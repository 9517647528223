<template>
  <div>
    <ul style="list-style-type: none">
      <li v-for="statusline of statusAudit" :key="statusline.id">
        <v-icon
          class="mb-1 mr-2"
          x-small
          :color="statusInfo(statusline.StatusId)?.Color"
          >mdi-circle</v-icon
        >
        <b>{{ statusInfo(statusline.StatusId)?.Status }}</b> -
        {{ new Date(statusline.TimeStamp).toLocaleString() }}
        <UserAvatarById class="mb-1 ml-1" :userId="statusline?.ModifiedById" />
      </li>
    </ul>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';

import UserAvatarById from '../general/UserAvatarById.vue';
export default {
  props: {
    processStep: {
      type: Object,
      required: false,
    },
    specification: {
      type: Object,
      required: false,
    },
    externalIssue: {
      type: Object,
      required: false,
    },
  },
  components: {
    UserAvatarById,
  },
  mixins: [
    makeFindMixin({ service: 'status-audit' }),
    makeFindMixin({ service: 'status' }),
  ],
  computed: {
    statusAuditParams() {
      const query = { $sort: { TimeStamp: 1 } };

      if (this.processStep) {
        query.ProcessStepId = this.processStep.id;
      } else if (this.specification) {
        query.SpecificationId = this.specification.id;
      } else if (this.externalIssue) {
        query.ExternalIssueId = this.externalIssue.id;
      }

      return {
        query,
      };
    },
    statusParams() {
      return {
        query: { $sort: { Number: 1 } },
      };
    },
  },
  methods: {
    statusInfo(statusId) {
      return this.status.find((f) => f.id == statusId);
    },
  },
};
</script>

<style lang="scss" scoped></style>
