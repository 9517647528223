<template>
  <div class="border-xl scaleImageMax">
    <v-card dense>
      <v-card-title
        class="text-h6"
        :class="{
          TitleBackground: true,
          obsolete: step.Obsolete,
        }"
        :style="
          step.GroupId != null
            ? accentStyle(group.find((f) => f.id == step.GroupId)?.allColors)
            : ''
        "
        >{{ step.Number }}. {{ step.Name }}</v-card-title
      >

      <v-card-text
        :style="
          step.GroupId != null
            ? accentStyle(group.find((f) => f.id == step.GroupId)?.allColors)
            : ''
        "
      >
        <span v-html="step.Description" class="ck-content"></span
      ></v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    step: {
      type: Object,
      required: true,
    },
    group: {
      type: Array,
      required: true,
    },
  },
  methods: {
    accentStyle(colors) {
      let boxShadow = '';

      let groupColors = [...colors];
      groupColors.reverse();
      // Start looping from the second color (index 1)
      groupColors.slice(1).forEach((color, index) => {
        const offset = (index + 1) * -5; // Calculate the offset for each color
        boxShadow += `${offset}px 0 0 0 ${color}, `;
      });

      // Remove the trailing comma and space from the last box-shadow entry
      boxShadow = boxShadow.slice(0, -2);

      const style = {
        'border-left': `5px solid ${groupColors[0]}`, // First border with the first color
        'box-shadow': boxShadow,
      };

      return style;
    },
  },
};
</script>

<style lang="scss" scoped></style>
