<template>
  <div>
    <div v-if="loading" class="text-center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>

    <div v-else>
      <v-icon @click="$router.go(-1)">mdi-arrow-left</v-icon>
      <v-treeview
        ref="tree"
        v-if="TreeData"
        activatable
        :items="TreeData"
        :open.sync="nodes.openNodes"
        :active.sync="nodes.activeNode"
        dense
        hoverable
        @update:open="saveNodes"
        @update:active="saveNodes"
      >
        <template v-slot:prepend="{ item }">
          <v-icon
            v-if="
              item.type == 'Detail' && item.CanStart && item.AllDone == true
            "
            color="green"
            small
            >mdi-play-circle</v-icon
          >
          <v-icon
            v-if="
              item.type == 'Detail' && item.CanStart && item.AllDone == false
            "
            color="primary"
            small
            >mdi-play-circle</v-icon
          >
          <v-icon
            v-if="item.type == 'Step' && item.Done == false"
            small
            :disabled="!item.CanStart"
            @click="toggleDone(item)"
            >{{
              !item.CanStart ? 'mdi-minus' : 'mdi-checkbox-blank-outline'
            }}</v-icon
          >
          <v-icon
            v-if="item.type == 'Step' && item.Done == true"
            @click="toggleDone(item)"
            small
            :disabled="!item.CanStart"
            color="green"
            >mdi-check-bold</v-icon
          >
        </template>
        <template v-slot:label="{ item }">
          <v-tooltip bottom open-delay="500">
            <template v-slot:activator="{ on }">
              <span v-on="on" @click="clickOnNode(item)"
                ><span v-if="item.Number">{{ item.Number }} - </span
                >{{ item.Name }}</span
              >
            </template>
            <span
              ><span v-if="item.Number">{{ item.Number }} - </span
              >{{ item.Name }}</span
            ></v-tooltip
          >
        </template>
      </v-treeview>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    testScenarioRun: {
      type: Array,
      required: true,
    },
    testScenarioRunDetail: {
      type: Array,
      required: true,
    },
    testScenarioRunSteps: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      TreeData: null,
      allOpened: false,
      lastOpen: null,
      open: null,
      nodes: { openNodes: [], activeNode: [] },
    };
  },
  watch: {
    loading: {
      handler: async function () {
        if (!this.loading) {
          await this.getTreeData();
        }
      },
      immediate: true,
    },
  },

  methods: {
    async updateTree() {
      await this.getTreeData();
    },
    async toggleDone(item) {
      item.Done = !item.Done;
      item.item.Done = !item.item.Done;

      try {
        await item.item.save();
      } catch (error) {
        console.error(error);
      }
      await this.getTreeData();
    },
    clickOnNode(node) {
      this.$router.push({
        query: {
          testRunId: node.GroupedId,
          testRunDetailId: node.TestScenarioRunDetailId,
          testRunStepId: node.TestScenarioRunStepId,
        },
      });
    },
    saveNodes() {
      window.localStorage.setItem(
        'treeViewTestRunState',
        JSON.stringify(this.nodes)
      );
    },
    scrollToActiveNode(activeNode) {
      if (activeNode && activeNode.length > 0) {
        const nodeId = activeNode[0];
        const nodeElement = this.$refs.tree.$el.querySelector(
          `[data-id="${nodeId}"]`
        );
        if (nodeElement) {
          nodeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    },
    handleSearch: function (val) {
      if (val) {
        if (!this.allOpened) {
          this.lastOpen = this.open;
          this.allOpened = true;
          this.$refs.tree.updateAll(true);
        }
      } else {
        this.$refs.tree.updateAll(false);
        this.allOpened = false;
        this.open = this.lastOpen;
      }
    },

    async getTreeData() {
      if (this.testScenarioRun.length > 0) {
        this.TreeData = this.testScenarioRun.map((i) => {
          return {
            id: i.id.toString(),
            GroupedId: i.id,
            name: i.Name,
            Name: i.Name,
            Number: i.Number,
            Order: i.Name,

            type: 'Run',
            // children: [],
            children: this.testScenarioRunDetail?.map((i2) => {
              return {
                id: `${i.id}.${i2.id}`,
                GroupedId: i.id,
                TestScenarioRunDetailId: i2.id,
                name: `${i2.Order}. ${i2.Name}`,
                Name: i2.Name,
                Number: i2.Order,
                AllDone: i2.AllDone,
                CanStart: i2.CanStart,

                Order: i2.Order,
                type: 'Detail',
                children: this.testScenarioRunSteps
                  .filter((f) => f.TestScenarioRunDetailId == i2.id)
                  .map((i3, index) => {
                    return {
                      id: `${i.id}.${i2.id}.${i3.id}`,
                      GroupedId: i.id,
                      TestScenarioRunDetailId: i2.id,
                      TestScenarioRunStepId: i3.id,
                      name: `${index + 1}. ${i3.Name}`,
                      Name: i3.Name,
                      Number: index + 1,
                      Done: i3.Done,
                      CanStart: i2.CanStart,

                      Order: index + 1,
                      type: 'Step',
                      item: i3,
                    };
                  }),
              };
            }),
          };
        });
      } else return [];
    },
  },

  async mounted() {
    this.nodes = JSON.parse(localStorage.getItem('treeViewTestRunState')) || {
      openNodes: [],
      activeNode: [],
    };

    await this.getTreeData();

    this.$nextTick(() => {
      this.scrollToActiveNode(this.nodes.activeNode);
    });
  },
};
</script>
