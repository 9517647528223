<template>
  <div>
    <RichEditorCK
      v-if="rteEditor === 'CKEditor'"
      v-model="content"
      :disabled="disabled"
      :placeholder="placeholder"
      @change="$emit('change')"
      @imageUploadProgress="$emit('imageUploadProgress', $event)"
    />
    <RichEditorSF
      v-else
      v-model="content"
      :disabled="disabled"
      :placeholder="placeholder"
      @change="$emit('change')"
      @imageUploadProgress="$emit('imageUploadProgress', $event)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RichEditorCK from '@/components/general/RichEditorCK.vue';
import RichEditorSF from '@/components/general/RichEditorSF.vue';

export default {
  components: {
    RichEditorCK,
    RichEditorSF,
  },
  props: {
    value: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
  },
  watch: {
    value: {
      handler(value) {
        this.content = value;
      },
    },
    content: {
      handler(value) {
        this.$emit('input', value);
      },
    },
  },
  computed: {
    ...mapGetters(['rteEditor']),
  },
  data() {
    return {
      content: this.value,
    };
  },
};
</script>

<style lang="scss" scoped></style>
