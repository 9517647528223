<template>
  <div>
    <v-dialog :value="true" persistent max-width="1100px" :retain-focus="false">
      <v-progress-linear
        v-if="isFindStatusPending"
        indeterminate
        color="secondary"
      ></v-progress-linear>

      <div v-if="item && !isFindStatusPending && currentSpecification">
        <v-form ref="form" v-model="validInput">
          <v-card flat>
            <v-card-title>
              <span class="headline">New/Edit Specification</span>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12" md="1">
                  <v-text-field
                    v-model="item['Number']"
                    label="Nr"
                    outlined
                    dense
                    disabled
                    :rules="[rules.number]"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="5">
                  <v-select
                    v-model="item['SpecificationTypeId']"
                    :disabled="!$can('update', item, 'SpecificationTypeId')"
                    :items="specificationType"
                    item-text="Type"
                    item-value="id"
                    label="Specification Type"
                    outlined
                    :rules="[rules.required]"
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    v-model="item['PriorityId']"
                    :disabled="!$can('update', item, 'PriorityId')"
                    :items="priority"
                    item-text="Name"
                    item-value="id"
                    label="Priority"
                    outlined
                    :rules="[rules.required]"
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    v-model="item['StatusId']"
                    :disabled="!$can('update', item, 'StatusId')"
                    :items="filteredStatus"
                    item-text="Status"
                    item-value="id"
                    label="Status"
                    outlined
                    :rules="[rules.required]"
                    dense
                  >
                    <template v-slot:item="{ item }">
                      <v-icon small class="mr-2" :color="item.Color">
                        mdi-circle
                      </v-icon>
                      {{ item.Status }} </template
                    ><template #selection="{ item }">
                      <v-icon small class="mr-2" :color="item.Color">
                        mdi-circle
                      </v-icon>
                      {{ item.Status }}
                    </template></v-select
                  >
                </v-col>
                <v-col cols="12" md="12">
                  <v-textarea
                    v-model="item['Description1']"
                    :disabled="!$can('update', item, 'Description1')"
                    :label="currentSpecification.Description1"
                    :rules="[rules.required]"
                    outlined
                    dense
                    rows="1"
                    auto-grow
                  >
                  </v-textarea>
                </v-col>
                <v-col
                  v-if="currentSpecification.Description2"
                  cols="12"
                  md="12"
                >
                  {{ currentSpecification.Description2
                  }}<RichEditor
                    v-model="item.Description2"
                    :disabled="false"
                    :placeholder="currentSpecification.Description2"
                    @imageUploadProgress="imageUploadProgress = $event"
                  ></RichEditor>
                </v-col>
                <v-col
                  v-if="currentSpecification.Description3"
                  cols="12"
                  md="12"
                >
                  {{ currentSpecification.Description3
                  }}<RichEditor
                    v-model="item.Description3"
                    :disabled="false"
                    :placeholder="currentSpecification.Description3"
                    @imageUploadProgress="imageUploadProgress = $event"
                  ></RichEditor>
                </v-col>
                <v-col
                  v-if="currentSpecification.Description4"
                  cols="12"
                  md="12"
                >
                  {{ currentSpecification.Description4
                  }}<RichEditor
                    v-model="item.Description4"
                    :disabled="false"
                    :placeholder="currentSpecification.Description4"
                    @imageUploadProgress="imageUploadProgress = $event"
                  ></RichEditor>
                </v-col>
                <v-col cols="12" md="12">
                  <v-autocomplete
                    v-model="item['VariantId']"
                    :items="variantList"
                    item-text="fullname"
                    item-value="id"
                    label="Link to Variant"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-checkbox
                v-model="item.Obsolete"
                :disabled="!$can('update', item, 'Obsolete')"
                :label="`Obsolete`"
              ></v-checkbox>
            </v-card-text>
            <v-card-actions>
              <v-btn
                v-if="$can('delete', item)"
                outlined
                @click="showRemoveMessage = true"
                >Remove</v-btn
              >
              <div v-if="showRemoveMessage" class="ml-5">
                Are you sure?
                <v-btn class="ml-2" @click="$emit('remove')" color="error"
                  >Yes</v-btn
                >
              </div>

              <v-spacer></v-spacer>
              <v-btn outlined @click="$emit('cancel')">Cancel</v-btn>
              <v-btn
                :disabled="!validInput"
                color="primary"
                :loading="saving || isUploadingImage"
                @click="$emit('save')"
              >
                Save
                <template v-slot:loader>
                  <v-progress-circular
                    color="white"
                    :rotate="-90"
                    :value="imageUploadProgress"
                  ></v-progress-circular>
                </template>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import RichEditor from '@/components/general/RichEditor.vue';
import { makeFindMixin } from 'feathers-vuex';
import { mapGetters } from 'vuex';
export default {
  name: 'SpecificationEditDialog',
  components: {
    RichEditor,
  },
  data() {
    return {
      validInput: true,
      errorMessage: null,
      showRemoveMessage: false,
      imageUploadProgress: 0,
      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
        number: (value) => !isNaN(value) || 'Must be a number',
      },
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    saving: { type: Boolean },
  },
  mixins: [
    makeFindMixin({ service: 'status' }),
    makeFindMixin({ service: 'priority' }),
    makeFindMixin({ service: 'specification-type' }),
    makeFindMixin({ service: 'variant' }),
  ],
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    isUploadingImage() {
      return this.imageUploadProgress > 0 && this.imageUploadProgress < 100;
    },
    statusParams() {
      return { query: { Active: true, $sort: { Number: 1 } } };
    },

    filteredStatus() {
      if (this.user.role == 'customer')
        return this.status.filter((s) => s.Role == this.user.role);
      else return this.status;
    },
    priorityParams() {
      return {};
    },
    specificationTypeParams() {
      return { query: { Active: true } };
    },
    currentSpecification() {
      return this.specificationType.filter((item) => {
        return item.id == parseInt(this.item.SpecificationTypeId);
      })[0];
    },
    variantParams() {
      return { query: { $sort: { 'process.Number': 1 } } };
    },
    variantList() {
      let list = [...this.variant];
      list.map((item) => {
        item.fullname = `${item.process.Number}.${item.Number} ${item.Name}`;
      });
      return list.sort((a, b) => {
        if (a.process.Number === b.process.Number) {
          return a.Number - b.Number;
        }
        return a.process.Number - b.process.Number;
      });
    },
  },
  watch: {
    'item.SpecificationTypeId': {
      handler: function (val) {
        //console.log(val);
        this.item.specification_type =
          // this.activeType =
          this.item.specification_type = this.specificationType.filter(
            (item) => {
              return item.id == val;
            }
          )[0];
      },
      immediate: true,
    },
  },
};
</script>
