var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{staticClass:"greenHeader text-pre-wrap secondary",attrs:{"headers":_vm.headersDetail,"items":_vm.testScenarioRunDetail,"sort-by":['Order'],"sort-desc":[false],"disable-pagination":"","hide-default-footer":"","loading":_vm.isFindTestScenarioRunDetailPending,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:`item.RunTest`,fn:function({ item }){return [(item.OpenBug)?_c('v-icon',{attrs:{"color":item.BlockingBug ? '#B20000FF' : '#FFC698'},on:{"click":function($event){return _vm.clickRun(item)}}},[_vm._v(" mdi-bug-play ")]):_vm._e(),(item.AllDone && !item.OpenBug)?_c('v-icon',{attrs:{"color":"#49BD91"},on:{"click":function($event){return _vm.clickRun(item)}}},[_vm._v(" mdi-play-circle ")]):_vm._e(),(!item.CanStart && !item.AllDone && !item.OpenBug)?_c('v-icon',{attrs:{"color":"#CBCBCB"}},[_vm._v(" mdi-play-circle ")]):_vm._e(),(item.CanStart && !item.AllDone && !item.OpenBug)?_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.clickRun(item)}}},[_vm._v(" mdi-play-circle ")]):_vm._e(),(false)?_c('span',[_vm._v(" "+_vm._s(item))]):_vm._e()]}},{key:`item.Progress`,fn:function({ item }){return [_c('v-progress-linear',{class:_vm.applyDark(
            item.totalSteps == item.totalStepsDone
              ? 'green'
              : item.BlockingBug
              ? '#B20000FF'
              : item.OpenBug
              ? '#FFC698'
              : 'primary'
          )
            ? 'white--text'
            : 'black--text',attrs:{"value":(item.totalStepsDone / item.totalSteps) * 100,"height":"20","dark":"","color":item.totalSteps == item.totalStepsDone
            ? 'green'
            : item.BlockingBug
            ? '#B20000FF'
            : item.OpenBug
            ? '#FFC698'
            : 'primary'}},[_c('strong',[_vm._v(_vm._s(item.totalStepsDone)+" / "+_vm._s(item.totalSteps))])])]}},(_vm.$can('update', 'test-scenario-run'))?{key:`item.DueDate`,fn:function(props){return [(!props.item.AllDone)?_c('v-edit-dialog',{attrs:{"return-value":props.item.DueDate,"large":""},on:{"update:returnValue":function($event){return _vm.$set(props.item, "DueDate", $event)},"update:return-value":function($event){return _vm.$set(props.item, "DueDate", $event)},"save":function($event){return props.item.save()}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-date-picker',{attrs:{"color":"primary","header-color":"primary","disabled":props.item.AllDone,"no-title":""},model:{value:(props.item.DueDate),callback:function ($$v) {_vm.$set(props.item, "DueDate", $$v)},expression:"props.item.DueDate"}})]},proxy:true}],null,true)},[(props.item.DueDate)?_c('v-chip',{staticClass:"mr-2",attrs:{"small":"","color":props.item.DueDate
              ? new Date(props.item.DueDate).toDateString() ===
                  new Date().toDateString() && !props.item.AllDone
                ? 'green'
                : new Date(props.item.DueDate) < new Date() &&
                  !props.item.AllDone
                ? 'red'
                : null
              : null,"disabled":props.item.AllDone,"dark":""}},[_vm._v(" "+_vm._s(props.item.DueDate ? new Date(props.item.DueDate).toLocaleDateString() : ''))]):_vm._e()],1):_vm._e()]}}:null,{key:`item.Assigned`,fn:function({ item, index }){return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function({ on: menu, attrs }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltip }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),{ ...tooltip, ...menu }),[(_vm.saving[index])?_c('v-progress-circular',{attrs:{"indeterminate":"","size":15,"width":1}}):_vm._e(),(!item.AssignedUser && !_vm.saving[index])?_c('v-icon',[_vm._v("mdi-account-plus")]):_vm._e(),(item.AssignedUser && !_vm.saving[index])?_c('v-avatar',{class:_vm.applyDark(item.AssignedUser.color)
                      ? 'white--text caption'
                      : 'black--text caption',attrs:{"text--color":"white","color":item.AssignedUser.color,"size":"25"}},[(item.AssignedUser?.gravatarUrl)?_c('img',{attrs:{"src":item.AssignedUser?.gravatarUrl,"alt":item.AssignedUser?.fullname}}):_c('span',[_vm._v(_vm._s(item.AssignedUser.fullname .match(/(\b\S)?/g) .join('') .match(/(^\S|\S$)?/g) .join('') .toUpperCase()))])]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.AssignedUser ? item.AssignedUser.fullname : 'No User Assigned'))])])]}}],null,true)},[_c('v-list',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"500px"},attrs:{"dense":""}},_vm._l((_vm.userList),function(user,index2){return _c('v-list-item',{key:user.id,attrs:{"value":index2},on:{"click":function($event){return _vm.assignUser(index, item, user.id)}}},[_c('v-list-item-title',[_c('v-avatar',{class:_vm.applyDark(user.color)
                    ? 'white--text caption mr-2'
                    : 'black--text caption mr-2',attrs:{"text--color":"white","color":user.color,"size":"25"}},[(user.gravatarUrl)?_c('img',{attrs:{"src":user.gravatarUrl,"alt":user.fullname}}):_c('span',[_vm._v(_vm._s(user.fullname .match(/(\b\S)?/g) .join('') .match(/(^\S|\S$)?/g) .join('') .toUpperCase()))])]),_vm._v(_vm._s(user.fullname))],1)],1)}),1)],1)]}}],null,true)}),(_vm.showDialog)?_c('TestScenarioRun',{attrs:{"testScenarioRun":_vm.testScenarioRun,"testScenarioRunDetail":_vm.currentItem},on:{"cancel":function($event){_vm.showDialog = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }