<template>
  <div>
    <v-row class="my-1"
      ><v-col cols="12" md="11">
        <span
          v-if="variant[0] && variant[0].process.Input"
          v-html="variant[0].process.Input"
          class="ck-content"
        ></span>
        <span
          v-if="variant[0] && variant[0].process.Output"
          v-html="variant[0].process.Output"
          class="ck-content"
        ></span>
      </v-col>
      <v-col cols="12" md="1">
        <v-tooltip
          bottom
          v-if="
            ($feature('cloudinaryChartIntegration') && variantUrl) ||
            ($feature('drawIOChartIntegration') && drawingUrl)
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              class="ml-4"
              @click="
                openWindow(
                  $feature('drawIOChartIntegration') && drawingUrl
                    ? drawingUrl
                    : variantUrl
                )
              "
            >
              mdi-image-search-outline
            </v-icon>
          </template>
          <span>Process Flows</span>
        </v-tooltip></v-col
      >
    </v-row>

    <v-tabs v-model="tab" active-class="activeTab">
      <v-tab v-for="item in variant" :key="item.id"
        ><v-icon
          v-if="
            (vendorResponseFinal(item) === 2 &&
              user.vendor.ResponsePhase == 1) ||
            (vendorResponseFinal2(item) === 2 && user.vendor.ResponsePhase == 2)
          "
          color="green"
          small
          class="mr-1"
          >mdi-check-bold
        </v-icon>
        <v-icon
          v-if="
            (vendorResponseFinal(item) === 0 &&
              user.vendor.ResponsePhase == 1) ||
            (vendorResponseFinal2(item) === 0 && user.vendor.ResponsePhase == 2)
          "
          color="red"
          small
          class="mr-1"
          >mdi-close-thick</v-icon
        >
        <v-icon
          v-if="
            (vendorResponseFinal(item) === 1 &&
              user.vendor.ResponsePhase == 1) ||
            (vendorResponseFinal2(item) === 1 && user.vendor.ResponsePhase == 2)
          "
          color="#FFC698"
          small
          class="mr-1"
          >mdi-close-thick</v-icon
        >{{ item.Name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="v in variant" :key="v.id">
        <VariantResponse :variant="v" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import { mapGetters } from 'vuex';
import VariantResponse from '@/components/VendorResponse/VariantResponse.vue';
export default {
  mixins: [
    makeFindMixin({ service: 'variant' }),
    makeFindMixin({ service: 'response' }),
    makeFindMixin({ service: 'process-step' }),
  ],
  data() {
    return {
      NrOfDrawings: 0,
    };
  },
  components: { VariantResponse },
  props: {
    tab: {
      type: Number,
      default: 0,
    },
    process: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    variantParams() {
      return {
        query: {
          ProcessId: this.process.id,
          $sort: {
            Number: 1,
          },
        },
      };
    },
    processStepParams() {
      return { query: { Obsolete: false } };
    },
    responseParams() {
      return {
        query: { VendorId: this.user.vendor ? this.user.vendor.id : null },
      };
    },
    drawingUrl() {
      if (this.NrOfDrawings > 0) {
        return `${this.$appConfig.clientBaseUrl}/#/drawing?process=${this.process.id}`;
      } else {
        return null;
      }
    },
    variantUrl() {
      let url = '';
      if (this.variant && this.variant[0]) {
        url =
          'https://res.cloudinary.com/foocus/image/upload/fl_sanitize/v' +
          Date.now() +
          '/' +
          this.$appConfig.customer.prefix +
          '/' +
          this.variant[0].process.Number.toString();
      }
      return url;
    },
  },
  methods: {
    openWindow(url) {
      window.open(
        url,
        'popup',
        'width=1440,height=1024,scrollbars=no,resizable=no'
      );
      return false;
    },
    vendorResponseFinal(v) {
      if (this.response) {
        const [response] = this.response.filter(
          (r) => r.VendorId == this.user.vendor.id && r.VariantId == v.id
        );
        return response ? (response.Final ? 2 : 1) : 0;
      } else return 0;
    },
    vendorResponseFinal2(v) {
      let steps = 0;
      if (v && this.processStep && this.response) {
        steps = this.processStep.filter(
          (p) =>
            p.VariantId == v.id &&
            !this.response.some(
              (r) => r.ProcessStepId == p.id && r.Final == true
            )
        );
      }

      return steps.length == 0 ? 2 : 1;
    },
  },
  async mounted() {
    const { Drawings } = this.$FeathersVuex.api;
    let result = await Drawings.find({
      query: { ProcessId: this.process.i, $limit: 0 },
    });
    this.NrOfDrawings = result.total;
  },
};
</script>

<style lang="scss" scoped></style>
