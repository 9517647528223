import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { abilitiesPlugin } from '@casl/vue';

import Toasted from 'vue-toasted';

import configPlugin from '@/config';
import i18n from './i18n';

import { registerLicense } from '@syncfusion/ej2-base';

import * as Sentry from '@sentry/vue';

// Registering Syncfusion license key
registerLicense(
  'ORg4AjUWIQA/Gnt2XVhhQlJHfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX9Rd0JjUHpbcX1TQGdU'
);

Sentry.init({
  Vue,
  dsn: 'https://e97702307464647adba8507381f6c604@o4508861620224000.ingest.de.sentry.io/4508862463934544',
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  environment: window.location.origin,

  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost', // Allow tracing on localhost
    /^https:\/\/.*\.loxi\.be$/, // Allow all loxi.be subdomains
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

Vue.use(configPlugin);

Vue.use(abilitiesPlugin, store.state.casl.ability);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

Vue.use(Toasted, {
  duration: 5000,
  position: 'bottom-center',
  keepOnHover: true,
  theme: 'bubble',
  iconPack: 'mdi',
});

Vue.toasted.register(
  'error',
  function (message) {
    return message;
  },
  {
    type: 'error',
    position: 'bottom-center',
    keepOnHover: true,
    theme: 'bubble',
    iconPack: 'mdi',
    icon: 'mdi-alert-circle',
    duration: 5000,
  }
);

Vue.toasted.register(
  'success',
  function (message) {
    return message;
  },
  {
    type: 'success',
    icon: 'mdi-check',
    duration: 3000,
  }
);

Vue.toasted.register(
  'info',
  function (message) {
    return message;
  },
  {
    type: 'info',
    icon: 'mdi-alert',
    duration: 3000,
  }
);

import VueQrcode from '@chenfengyuan/vue-qrcode';

Vue.component(VueQrcode.name, VueQrcode);
