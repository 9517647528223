<template>
  <v-container
    ><FeathersVuexFind
      v-slot="{ items: departments }"
      service="department"
      :params="{}"
      watch="params"
    >
      <section>
        <v-data-table
          :headers="filteredHeaders"
          :items="departments"
          :items-per-page="100"
          :sort-by="['Name']"
          :sort-desc="[false]"
          item-key="id"
          class="elevation-1"
          hide-default-footer
        >
          <template v-if="$can('create', 'department')" v-slot:footer>
            <v-toolbar flat color="white">
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="800px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    elevation="0"
                    class="mx-2"
                    fab
                    small
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                  >
                    <v-icon dark> mdi-plus </v-icon>
                  </v-btn>
                </template>
                <v-form ref="form" v-model="validInput">
                  <v-card>
                    <v-card-title>
                      <span class="headline">Add Department</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.Name"
                              label="Department"
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.CustomerDepartment"
                              label="Number"
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.ERPModule"
                              label="ERPModule"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.Weight"
                              label="Weight"
                              :rules="[rules.number]"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn outlined @click="Close()">Cancel</v-btn>
                      <v-btn
                        :disabled="!validInput"
                        color="primary"
                        @click="Add()"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-card></v-form
                >
              </v-dialog>
            </v-toolbar>
          </template>

          <template
            v-if="$can('update', 'department')"
            v-slot:[`item.Name`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.Name"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.Name }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.Name"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template
            v-if="$can('update', 'department')"
            v-slot:[`item.CustomerDepartment`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.CustomerDepartment"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.CustomerDepartment }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.CustomerDepartment"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template
            v-if="$can('update', 'department')"
            v-slot:[`item.ERPModule`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.ERPModule"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.ERPModule }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.ERPModule"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template
            v-if="$can('update', 'department')"
            v-slot:[`item.Weight`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.Weight"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.Weight }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.Weight"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template
            v-if="$can('delete', 'department')"
            v-slot:[`item.actions`]="{ item }"
          >
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
          <template
            v-if="$can('create', 'test-case')"
            v-slot:[`item.testcase`]="{ item }"
          >
            <v-icon small @click="departmentItem = item">
              mdi-briefcase-plus
            </v-icon></template
          >
          <template
            v-if="$can('update', 'process-step')"
            v-slot:[`item.update`]="{ item }"
          >
            <v-icon
              v-if="$can('update', 'process-step')"
              small
              class="mr-2"
              @click="updateStatus(item)"
              color="primary"
            >
              mdi-update
            </v-icon>
          </template>
        </v-data-table>
      </section></FeathersVuexFind
    >
    <UpdateStatus
      v-if="currentItem && updateDialog"
      v-model="updateDialog"
      :id="currentItem.id"
      :type="'department'"
    />
    <Modal
      v-if="departmentItem"
      :title="`Add Test Cases for ${departmentItem.Name}`"
      :hideCancel="true"
      width="1000px"
      @confirm="departmentItem = null"
    >
      <TestCases :Department="departmentItem" />
    </Modal>
  </v-container>
</template>

<script>
import UpdateStatus from '@/components/Variant/UpdateStatus.vue';
import TestCases from './TestCases.vue';
import Modal from '../general/Modal.vue';
import feathersClient from '../../feathers-client';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';

export default {
  components: { UpdateStatus, TestCases, Modal },
  data() {
    return {
      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
        number: (value) => !isNaN(value) || 'Must be a number',
      },
      validInput: false,
      currentItem: null,

      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'Name',
        },
        {
          text: 'Number',
          align: 'start',
          sortable: true,
          value: 'CustomerDepartment',
        },
        {
          text: 'External Department',
          align: 'start',
          sortable: true,
          value: 'ERPModule',
        },
        {
          text: 'Weight',
          align: 'start',
          sortable: true,
          value: 'Weight',
        },
        {
          text: 'Add Test Cases',
          value: 'testcase',
          sortable: false,
          align: 'center',
        },
        { text: 'Delete', value: 'actions', sortable: false, align: 'center' },
        {
          text: 'Update Status',
          value: 'update',
          sortable: false,
          align: 'center',
        },
      ],
      dialog: false,
      editedIndex: -1,
      editedItem: {
        Name: '',
        CustomerDepartment: '',
        ERPModule: '',
        Weight: 0,
      },
      defaultItem: {
        Name: '',
        CustomerDepartment: '',
        ERPModule: '',
        Weight: 0,
      },
      departmentItem: null,
      updateDialog: false,
    };
  },
  computed: {
    filteredHeaders() {
      return this.headers.filter((item) => {
        return this.$can('delete', 'department') ? true : item.text != 'Delete';
      });
    },
  },
  methods: {
    updateStatus(item) {
      this.currentItem = item;
      this.updateDialog = true;
    },
    save(item) {
      item
        .save()
        .then((saved) =>
          handleSaveResponse(saved.Name, 'Department', 'updated')
        )
        .catch((error) => handleErrorResponse(error));
    },
    open() {},
    cancel() {},
    close() {},
    Add() {
      feathersClient
        .service('department')
        .create(this.editedItem)
        .then((saved) => handleSaveResponse(saved.Name, 'Department', 'added'))
        .catch((error) => handleErrorResponse(error));

      this.Close();
    },
    Close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },

    deleteItem(item) {
      item
        .remove()
        .then((saved) =>
          handleSaveResponse(saved.Name, 'Department', 'removed')
        )
        .catch((error) => handleErrorResponse(error));
    },
  },
};
</script>

<style></style>
