<template>
  <div>
    <v-dialog :value="true" persistent max-width="1100px" :retain-focus="false">
      <v-progress-linear
        v-if="isPending"
        indeterminate
        color="secondary"
      ></v-progress-linear>

      <div v-if="item && !isFindStatusPending && !isFindProcessStepPending">
        <v-form ref="form" v-model="validInput">
          <v-card flat>
            <v-alert type="warning" v-if="tokensUser && !externalAccountLinked"
              >{{ $appConfig.externalIssues }} account not linked. Link your
              account before creating external issues. The link Url is in your
              user menu at the top right.</v-alert
            >

            <v-card-title>
              <span class="headline">New/Edit Issue</span>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12" md="8">
                  <v-text-field
                    v-model="item.Title"
                    :disabled="
                      !(
                        $can('update', item, 'Title') ||
                        $can('create', 'external-issues')
                      ) ||
                      (item.ExternalId?.length > 0 &&
                        !updateFields.includes('Title'))
                    "
                    outlined
                    dense
                    hide-details="true"
                    placeholder="Title"
                    :rules="[rules.required]"
                    ><template v-slot:label>
                      Title
                      <v-icon
                        class="mb-3 pr-1"
                        v-if="syncFields.includes('Title')"
                        x-small
                        >mdi-connection</v-icon
                      >
                    </template></v-text-field
                  >
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="item.IssueTypeId"
                    :disabled="
                      !(
                        $can('update', item, 'IssueTypeId') ||
                        $can('create', 'external-issues')
                      ) ||
                      (item.ExternalId?.length > 0 &&
                        !updateFields.includes('Type'))
                    "
                    :items="filteredIssueTypes"
                    item-text="Type"
                    item-value="id"
                    label="Type"
                    outlined
                    :rules="[rules.required]"
                    dense
                    hide-details="true"
                    @change="item.StatusId = null"
                    ><template v-slot:label>
                      Type
                      <v-icon
                        class="mb-3 pr-1"
                        v-if="syncFields.includes('Type')"
                        x-small
                        >mdi-connection</v-icon
                      >
                    </template>
                    <template v-slot:item="{ item }">
                      <v-icon small class="mr-2"> {{ item.Icon }} </v-icon>
                      {{ item.Type
                      }}<v-icon
                        v-if="item.ExternalType"
                        class="mb-3 ml-1"
                        x-small
                        >mdi-connection</v-icon
                      >
                    </template>
                    <template #selection="{ item }">
                      <v-icon small class="mr-2"> {{ item.Icon }} </v-icon>
                      {{ item.Type
                      }}<v-icon
                        v-if="item.ExternalType"
                        class="mb-3 ml-1"
                        x-small
                        >mdi-connection</v-icon
                      >
                    </template></v-select
                  >
                </v-col>

                <v-col cols="12" md="12">
                  <span class="text-body-1">Description</span
                  ><v-icon
                    class="mb-3"
                    v-if="syncFields.includes('Description')"
                    x-small
                    >mdi-connection</v-icon
                  ><RichEditor
                    v-model="item.Content1"
                    v-if="show"
                    :disabled="
                      !(
                        $can('update', item, 'Content1') ||
                        $can('create', 'external-issues')
                      ) ||
                      (item.ExternalId?.length > 0 &&
                        !updateFields.includes('Description'))
                    "
                    placeholder="Describe the issue"
                    @imageUploadProgress="imageUploadProgress = $event"
                  ></RichEditor>
                </v-col>
                <v-col cols="12" md="12" v-if="false">
                  Extra Description<v-icon
                    class="mb-3"
                    v-if="syncFields.includes('Extra Description')"
                    x-small
                    >mdi-connection</v-icon
                  ><RichEditor
                    v-model="item.Content2"
                    :disabled="
                      !(
                        $can('update', item, 'Content2') ||
                        $can('create', 'external-issues')
                      ) ||
                      (item.ExternalId?.length > 0 &&
                        !updateFields.includes('Extra Description'))
                    "
                  ></RichEditor>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="item['StatusId']"
                    :disabled="
                      !(
                        $can('update', item, 'StatusId') ||
                        $can('create', 'external-issues')
                      ) ||
                      (item.ExternalId?.length > 0 &&
                        !updateFields.includes('Status'))
                    "
                    :items="filteredStatus"
                    item-text="Issue Status"
                    item-value="id"
                    label="Status"
                    outlined
                    :rules="[rules.required]"
                    dense
                    hide-details="true"
                    ><template v-slot:label>
                      Issue Status
                      <v-icon
                        class="mb-3 pr-1"
                        v-if="syncFields.includes('Status')"
                        x-small
                        >mdi-connection</v-icon
                      >
                    </template>
                    <template v-slot:item="{ item }">
                      <v-icon small class="mr-2" :color="item.Color">
                        mdi-circle
                      </v-icon>
                      {{ item.Status }} </template
                    ><template #selection="{ item }">
                      <v-icon small class="mr-2" :color="item.Color">
                        mdi-circle
                      </v-icon>
                      {{ item.Status }}
                    </template></v-select
                  >
                </v-col>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    v-model="item['AssignedId']"
                    :disabled="
                      !(
                        $can('update', item, 'AssignedId') ||
                        $can('create', 'external-issues')
                      ) ||
                      (item.ExternalId?.length > 0 &&
                        !updateFields.includes('Assignee'))
                    "
                    :items="users"
                    item-text="fullname"
                    item-value="id"
                    label="Assigned User"
                    outlined
                    clearable
                    dense
                    hide-details="true"
                    ><template v-slot:label>
                      Assigned User
                      <v-icon
                        class="mb-3 pr-1"
                        v-if="syncFields.includes('Assignee')"
                        x-small
                        >mdi-connection</v-icon
                      > </template
                    ><template v-slot:item="{ item }">
                      <UserAvatar
                        :Name="item.fullname"
                        :Image="item.gravatarUrl"
                        :Color="item.color"
                      />
                      {{ item.fullname }}</template
                    >
                    ><template #selection="{ item }">
                      <UserAvatar
                        :Name="item.fullname"
                        :Image="item.gravatarUrl"
                        :Color="item.color"
                      />
                      {{ item.fullname }}</template
                    >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="4" align="right">
                  <v-checkbox
                    class="d-inline-flex mr-4"
                    v-model="item.Blocking"
                    :disabled="
                      !(
                        $can('update', item, 'Blocking') ||
                        $can('create', 'external-issues')
                      ) ||
                      (item.ExternalId?.length > 0 &&
                        !updateFields.includes('Severity'))
                    "
                    :label="`Blocking`"
                    dense
                    hide-details="true"
                    ><template v-slot:label>
                      Blocking
                      <v-icon
                        class="mb-3 pr-1"
                        v-if="syncFields.includes('Severity')"
                        x-small
                        >mdi-connection</v-icon
                      >
                    </template></v-checkbox
                  ></v-col
                >

                <v-col cols="12" md="4">
                  <v-menu
                    v-model="menuDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="item.DueDate"
                        label="Due Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                        hide-details="true"
                        :disabled="
                          !(
                            $can('update', item, 'DueDate') ||
                            $can('create', 'external-issues')
                          ) ||
                          (item.ExternalId?.length > 0 &&
                            !updateFields.includes('Due Date'))
                        "
                        ><template v-slot:label>
                          Due Date
                          <v-icon
                            class="mb-3 pr-1"
                            v-if="syncFields.includes('Due Date')"
                            x-small
                            >mdi-connection</v-icon
                          >
                        </template></v-text-field
                      >
                    </template>
                    <v-date-picker
                      v-model="item.DueDate"
                      color="primary"
                      header-color="primary"
                      @input="menuDate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="item['DueStatusId']"
                    :disabled="
                      !(
                        $can('update', item, 'DueStatusId') ||
                        $can('create', 'external-issues') ||
                        (item.ExternalId?.length > 0 &&
                          !updateFields.includes('Due Status'))
                      )
                    "
                    :items="status"
                    item-text="Status"
                    item-value="id"
                    label="Due Status"
                    outlined
                    dense
                    hide-details="true"
                    ><template v-slot:label>
                      Due Status
                      <v-icon
                        class="mb-3 pr-1"
                        v-if="syncFields.includes('Due Status')"
                        x-small
                        >mdi-connection</v-icon
                      >
                    </template>
                    <template v-slot:item="{ item }">
                      <v-icon small class="mr-2" :color="item.Color">
                        mdi-circle
                      </v-icon>
                      {{ item.Status }} </template
                    ><template #selection="{ item }">
                      <v-icon small class="mr-2" :color="item.Color">
                        mdi-circle
                      </v-icon>
                      {{ item.Status }}
                    </template></v-select
                  >
                </v-col>
                <v-spacer></v-spacer
                ><v-btn
                  icon
                  @click="showProcessLinks = !showProcessLinks"
                  class="mt-2 mr-2"
                >
                  <v-icon>{{
                    showProcessLinks ? 'mdi-chevron-up' : 'mdi-chevron-down'
                  }}</v-icon>
                </v-btn>
              </v-row>
            </v-card-text>
            <v-expand-transition>
              <v-card
                v-if="showProcessLinks"
                class="transition-fast-in-fast-out v-card--reveal ma-2"
                style="height: 100%"
                outlined
                ><v-card-subtitle class="ml-1 pl-1 text-subtitle-1"
                  ><v-icon class="mr-2">mdi-link-variant</v-icon
                  >Links</v-card-subtitle
                >
                <v-card-text class="pb-0">
                  <v-row
                    ><v-col cols="12" md="4">
                      <v-autocomplete
                        v-model="item['ProcessId']"
                        :disabled="
                          !(
                            $can('update', item, 'ProcessId') ||
                            $can('create', 'external-issues')
                          )
                        "
                        :items="processList"
                        item-text="fullname"
                        item-value="id"
                        label="Process"
                        outlined
                        clearable
                        dense
                        hide-details="true"
                        @change="
                          item.VariantId = null;
                          item.ProcessStepId = null;
                          item.testScenarioRunStepId = null;
                        "
                      ></v-autocomplete
                    ></v-col>
                    <v-col cols="12" md="4">
                      <v-autocomplete
                        v-model="item['VariantId']"
                        :disabled="
                          !(
                            $can('update', item, 'VariantId') ||
                            $can('create', 'external-issues')
                          )
                        "
                        :items="variantList"
                        item-text="fullname"
                        item-value="id"
                        label="Variant"
                        outlined
                        clearable
                        dense
                        hide-details="true"
                        @change="
                          item.ProcessStepId = null;
                          item.testScenarioRunStepId = null;
                        "
                      ></v-autocomplete></v-col
                    ><v-col cols="12" md="4">
                      <v-autocomplete
                        v-model="item['ProcessStepId']"
                        :disabled="
                          !(
                            $can('update', item, 'ProcessStepId') ||
                            $can('create', 'external-issues')
                          )
                        "
                        :items="processStepList"
                        item-text="fullname"
                        item-value="id"
                        label="Process Step"
                        outlined
                        clearable
                        dense
                        hide-details="true"
                        @change="item.testScenarioRunStepId = null"
                      ></v-autocomplete
                    ></v-col>
                    <v-col cols="12" md="4">
                      <v-autocomplete
                        v-model="item['TestScenarioRunStepId']"
                        :disabled="
                          !(
                            $can('update', item, 'TestScenarioRunStepId') ||
                            $can('create', 'external-issues')
                          )
                        "
                        :items="fullTestScenarioRunSteps"
                        item-text="fullName"
                        item-value="id"
                        label="Test Scenario Step"
                        outlined
                        clearable
                        dense
                        hide-details="true"
                      ></v-autocomplete
                    ></v-col>
                  </v-row>
                  <v-row class="mb-2"
                    ><v-col cols="12" md="4">
                      <v-text-field
                        v-model="item.ExternalId"
                        :disabled="
                          !(
                            $can('update', item, 'ExternalId') ||
                            $can('create', 'external-issues')
                          )
                        "
                        outlined
                        dense
                        hide-details="true"
                        label="External Id"
                        placeholder="External Id"
                      ></v-text-field></v-col
                    ><v-col cols="12" md="4">
                      <v-text-field
                        v-model="item.Url"
                        :disabled="
                          !(
                            $can('update', item, 'Url') ||
                            $can('create', 'external-issues')
                          )
                        "
                        outlined
                        dense
                        hide-details="true"
                        label="Url"
                        placeholder="Url"
                      ></v-text-field></v-col
                  ></v-row>
                </v-card-text>
              </v-card>
            </v-expand-transition>
            <v-card-actions>
              <v-btn
                v-if="
                  $can('delete', 'external-issues') &&
                  item.id &&
                  (!item.IssueType?.ExternalType ||
                    (item.IssueType?.ExternalType && !item.ExternalId))
                "
                outlined
                @click="showRemoveMessage = true"
                >Remove</v-btn
              >
              <div v-if="showRemoveMessage" class="ml-5">
                Are you sure?
                <v-btn
                  class="ml-2"
                  @click="$emit('remove')"
                  color="error"
                  :loading="saving"
                  >Yes</v-btn
                >
              </div>
              <v-spacer></v-spacer>
              <v-btn outlined @click="$emit('cancel')">Cancel</v-btn>
              <v-btn
                :disabled="!validInput"
                :loading="saving || isUploadingImage"
                color="primary"
                @click="$emit('save')"
              >
                Save
                <template v-slot:loader>
                  <v-progress-circular
                    color="white"
                    :rotate="-90"
                    :value="imageUploadProgress"
                  ></v-progress-circular>
                </template>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import RichEditor from '@/components/general/RichEditor.vue';
import UserAvatar from '@/components/general/UserAvatar.vue';
import { applyDark } from '@/utils/Utilities';
import { makeFindMixin } from 'feathers-vuex';
import { mapGetters } from 'vuex';

export default {
  name: 'IssueEditDialog',
  components: {
    RichEditor,
    UserAvatar,
  },
  data() {
    return {
      prioriteit: ['Must', 'Should'],
      validInput: true,
      errorMessage: null,
      showRemoveMessage: false,
      showProcessLinks: false,
      menuDate: false,
      show: true,
      filteredStatus: [],
      imageUploadProgress: 0,
      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
      },
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    saving: { type: Boolean },
  },
  mixins: [
    makeFindMixin({ service: 'status' }),
    makeFindMixin({ service: 'issue-type-status', watch: 'item.IssueTypeId' }),
    makeFindMixin({ service: 'issue-type-fields', watch: 'item.IssueTypeId' }),
    makeFindMixin({ service: 'issue-type' }),
    makeFindMixin({ service: 'priority' }),
    makeFindMixin({ service: 'users' }),
    makeFindMixin({ service: 'tokens-user' }),
    makeFindMixin({ service: 'process' }),
    makeFindMixin({ service: 'export-queries' }),
    makeFindMixin({ service: 'variant', watch: ['item.ProcessId'] }),
    makeFindMixin({
      service: 'test-scenario-run-steps',
      watch: 'item.ProcessStepId',
    }),
    makeFindMixin({
      service: 'process-step',
      watch: ['item.VariantId', 'item.ProcessId'],
    }),
  ],
  watch: {
    isPending: {
      handler() {
        if (!this.isPending) {
          if (!('IssueTypeId' in this.item) || this.item.IssueTypeId == null) {
            this.item.IssueTypeId = this.filteredIssueTypes[0].id;
          }
          this.initializeFields();
          this.filteredStatus = this.status.filter((status) =>
            this.issueTypeStatus.some(
              (issueStatus) => issueStatus.StatusId === status.id
            )
          );
        }
      },
      deep: true,
    },
    'item.IssueTypeId': {
      handler() {
        if (this.item.IssueTypeId) {
          this.filteredStatus = this.status.filter((status) =>
            this.issueTypeStatus.some(
              (issueStatus) => issueStatus.StatusId === status.id
            )
          );
        } else {
          this.filteredStatus = [];
        }
      },
      deep: true,
    },
    'item.ProcessStepId'() {
      this.testScenarioRunSteps.map((item) => {
        item.fullName = `Test Run ${item.test_scenario_run_detail.test_scenario_run.Number}.${item.test_scenario_run_detail.Order} - ${item.Name}`;
      });
      return this.testScenarioRunSteps;
    },

    checkChangedfields: {
      handler() {
        this.initializeFields();
      },
      deep: true,
    },
  },
  methods: {
    applyDark,
    fullname(item) {
      return item.Name + ' koko';
    },
    references() {
      let listString = '';
      for (const ref of this.exportQueriesLatestQuery.response) {
        if (ref.InputName != null) {
          listString += `<li>${ref.OutputName} : ${ref.InputName}</li>`;
        }
      }
      return listString;
    },
    initializeFields() {
      if (!this.item?.id) {
        if (this.filteredStatus?.length > 0)
          this.item.StatusId = this.filteredStatus[0].id;

        let ProcessStep = null;
        let Variant = null;
        let Process = null;

        if (this.item.Content1 == '<p><br/></p>') this.item.Content1 = '';

        if (this.item.TestScenarioRunStepId) {
          ProcessStep = this.processStep.find(
            (f) => f.id == this.item.ProcessStepId
          );

          // this.item.Title = this.item.Title
          //   ? this.item.Title
          //   : `Probleem met '${ProcessStep.variant.Name}' bij '${ProcessStep.Name}'`;
          this.item.Content1 =
            this.item.Content1?.length > 0
              ? this.item.Content1
              : `<p><a target=”_blank”  href="${
                  this.$appConfig.clientBaseUrl
                }/#/processdetail/${ProcessStep.variant.process.id}&${
                  ProcessStep.variant.process.Number
                }">${
                  ProcessStep.variant.process.Name
                }</a> > <a target=”_blank”  href="${
                  this.$appConfig.clientBaseUrl
                }/#/processstep/${ProcessStep.variant.id}">${
                  ProcessStep.variant.Name
                }</a> > ${ProcessStep.Name}</p>
                <p>Issue logged by ${this.user.fullname}</p>
              <p><b>Test Run</b>: <a href="/#/testRunBrowser?testRunId=${
                this.testScenarioRunSteps.find(
                  (f) => f.id == this.item.TestScenarioRunStepId
                )?.test_scenario_run_detail?.test_scenario_run.id
              }}">${
                  this.testScenarioRunSteps.find(
                    (f) => f.id == this.item.TestScenarioRunStepId
                  )?.test_scenario_run_detail?.test_scenario_run.id
                }</a>. ${
                  this.testScenarioRunSteps.find(
                    (f) => f.id == this.item.TestScenarioRunStepId
                  )?.test_scenario_run_detail?.test_scenario_run.Name
                } </p>
              <p><b>Test Run Line</b>: ${
                this.testScenarioRunSteps.find(
                  (f) => f.id == this.item.TestScenarioRunStepId
                )?.test_scenario_run_detail?.Order
              }. ${
                  this.testScenarioRunSteps.find(
                    (f) => f.id == this.item.TestScenarioRunStepId
                  )?.test_scenario_run_detail?.Name
                } </p>
              <p><b>Test Run Step</b>: ${
                this.testScenarioRunSteps.find(
                  (f) => f.id == this.item.TestScenarioRunStepId
                )?.Number
              }. ${
                  this.testScenarioRunSteps.find(
                    (f) => f.id == this.item.TestScenarioRunStepId
                  )?.Name
                } </p>
                <p><b>References</b>:</p>
                <ul>
                  ${this.references()}
                </ul>
                <br>
                `;
        } else if (this.item.ProcessStepId) {
          ProcessStep = this.processStep.find(
            (f) => f.id == this.item.ProcessStepId
          );

          // this.item.Title = this.item.Title
          //   ? this.item.Title
          //   : `Probleem met '${ProcessStep.variant.Name}' bij '${ProcessStep.Name}'`;
          this.item.Content1 =
            this.item.Content1?.length > 0
              ? this.item.Content1
              : `<p><a target=”_blank”  href="${this.$appConfig.clientBaseUrl}/#/processdetail/${ProcessStep.variant.process.id}&${ProcessStep.variant.process.Number}">${ProcessStep.variant.process.Name}</a> > <a target=”_blank”  href="${this.$appConfig.clientBaseUrl}/#/processstep/${ProcessStep.variant.id}">${ProcessStep.variant.Name}</a> > ${ProcessStep.Name}</p>
                        <p>Issue logged by ${this.user.fullname}</p><p></p>`;
        } else if (this.item.VariantId) {
          Variant = this.variant.find((f) => f.id == this.item.VariantId);
          // this.item.Title = this.item.Title
          //   ? this.item.Title
          //   : `Probleem met '${Variant.Name}'`;
          this.item.Content1 =
            this.item.Content1?.length > 0
              ? this.item.Content1
              : `<p><a target=”_blank”  href="${this.$appConfig.clientBaseUrl}/#/processdetail/${Variant.process.id}&${Variant.process.Number}">${Variant.process.Name}</a> > <a target=”_blank”  href="${this.$appConfig.clientBaseUrl}/#/processstep/${Variant.id}">${Variant.Name}</a></p>
                        <p>Issue logged by ${this.user.fullname}</p><p></p>`;
        } else if (this.item.ProcessId) {
          Process = this.process.find((f) => f.id == this.item.ProcessId);
          // this.item.Title = this.item.Title
          //   ? this.item.Title
          //   : `Probleem met '${Process.Name}'`;
          this.item.Content1 =
            this.item.Content1?.length > 0
              ? this.item.Content1
              : `<p><a target=”_blank”  href="${this.$appConfig.clientBaseUrl}/#/processdetail/${Process.id}&${Process.Number}">${Process.Name}</a></p>
                        <p>Issue logged by ${this.user.fullname}</p><p></p>`;
        } else {
          this.item.Content1 =
            this.item.Content1?.length > 0
              ? this.item.Content1
              : `<p>Issue logged by ${this.user.fullname}</p><p></p>`;
        }
        // Rerender content field
        this.show = false;
        this.show = true;
      }
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    isUploadingImage() {
      return this.imageUploadProgress > 0 && this.imageUploadProgress < 100;
    },
    checkChangedfields() {
      return `${this.item.IssueTypeId}|${this.item.ProcessId}|${this.item.VariantId}|${this.item.ProcessStepId}|${this.item.TestScenarioRunStepId}`;
    },
    isPending() {
      return (
        this.isFindStatusPending ||
        this.isFindIssueTypeStatusPending ||
        this.isFindIssueTypeFieldsPending ||
        this.isFindPriorityPending ||
        this.FindExportQueriesPending ||
        this.isFindIssueTypePending ||
        this.isFindProcessStepPending ||
        this.isFindUsersPending ||
        this.isFindTokensUserPending ||
        this.isFindProcessPending ||
        this.isFindVariantPending ||
        this.isFindTestScenarioRunStepsPending
      );
    },
    externalAccountLinked() {
      if (!this.$appConfig.externalIssues) {
        return true; // If no external system configured aways return true
      } else if (this.$appConfig.externalIssues == 'DevOps') {
        return true;
      } else {
        return (
          this.tokensUser?.filter(
            (f) =>
              f.token.Application?.includes(this.$appConfig.externalIssues) &&
              f.AccessToken?.length > 0
          ).length > 0
        );
      }
    },
    statusParams() {
      return { query: { $sort: { Number: 1 } } };
    },
    issueTypeStatusParams() {
      return { query: { IssueTypeId: this.item.IssueTypeId } };
    },
    issueTypeFieldsParams() {
      return {
        query: {
          IssueTypeId: this.item.IssueTypeId,
          Update: { $in: ['Both', 'Loxi', 'No'] },
        },
      };
    },
    updateFields() {
      return this.issueTypeFields
        .filter(
          (f) => (f.Update == 'No' && f.Create == 'No') || f.Update != 'No'
        )
        .map((f) => f.Field);
    },
    syncFields() {
      return this.issueTypeFields
        .filter((f) => f.Update != 'No')
        .map((f) => f.Field);
    },
    usersParams() {
      return { query: { Active: true, $sort: { fullname: 1 } } };
    },
    tokensUserParams() {
      return { query: { TokenUserId: this.user.id } };
    },
    issueTypeParams() {
      return { query: { $sort: { Order: 1 } } };
    },
    filteredIssueTypes() {
      if (!this.externalAccountLinked) {
        return this.issueType.filter(
          (f) => f.ExternalType == null || f.ExternalType?.length == 0
        );
      } else return this.issueType;
    },
    testScenarioRunId() {
      return this.testScenarioRunSteps[0]?.test_scenario_run_detail
        .TestScenarioRunId;
    },
    exportQueriesParams() {
      return {
        query: { type: 'testHistory', id: this.testScenarioRunId },
      };
    },
    priorityParams() {
      return {};
    },
    processParams() {
      return { query: { $sort: { Number: 1 } } };
    },
    processList() {
      let list = [...this.process];
      list.map((item) => {
        item.fullname = `${item.Number} ${item.Name}`;
      });
      return list;
    },

    variantParams() {
      return {
        query: { ProcessId: this.item.ProcessId, $sort: { Number: 1 } },
      };
    },
    variantList() {
      let list = [...this.variant];
      list.map((item) => {
        item.fullname = `${item.Number} ${item.Name}`;
      });
      return list;
    },
    processStepParams() {
      return {
        query: { VariantId: this.item.VariantId, $sort: { Number: 1 } },
      };
    },
    processStepList() {
      let list = [...this.processStep];
      list.map((item) => {
        item.fullname = `${item.Number} ${item.Name}`;
      });
      return list;
    },
    testScenarioRunStepsParams() {
      return {
        query: { ProcessStepId: this.item.ProcessStepId },
      };
    },
    fullTestScenarioRunSteps() {
      this.testScenarioRunSteps.map((item) => {
        item.fullName = `Test Run ${item.test_scenario_run_detail.test_scenario_run.id}.${item.test_scenario_run_detail.Order} - ${item.Name}`;
      });
      return this.testScenarioRunSteps;
    },
  },
  mounted() {
    if (
      this.filteredStatus &&
      this.filteredStatus.length > 0 &&
      this.item &&
      this.item.StatusId == null
    ) {
      this.item.StatusId = this.filteredStatus[0].id;
    }
  },
};
</script>
