<template>
  <div>
    <v-alert
      v-if="erpapplicationVariant?.length > 0"
      color="#eeeeee"
      class="my-2"
    >
      <h4>Application</h4>
      <v-list
        dense
        class="pl-0 ml-0 py-0 my-0"
        style="background-color: transparent !important"
      >
        <v-list-item
          v-for="app in erpapplicationVariant.sort((a, b) => {
            if (a.erp_application.Name < b.erp_application.Name) return -1;
            if (a.erp_application.Name > b.erp_application.Name) return 1;
            return 0;
          })"
          :key="app.id"
        >
          <v-list-item-content class="my-0 py-1" @click="toggleSelection(app)">
            <v-tooltip top open-delay="1000">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-title v-bind="attrs" v-on="on">
                  <span v-if="selectedErpApplicationIds && allowSelection">
                    <v-icon
                      v-if="newSelection.includes(app.erp_application.id)"
                      small
                      class="mr-1"
                      >mdi-checkbox-outline</v-icon
                    >
                    <v-icon v-else small class="mr-1"
                      >mdi-checkbox-blank-outline</v-icon
                    >
                  </span>
                  <a
                    v-if="app.erp_application.Link"
                    :href="app.erp_application.Link"
                    target="_blank"
                  >
                    <span v-if="app.erp_application.Customized">*</span
                    >{{ app.erp_application.Name }}
                  </a>
                  <span v-else>{{ app.erp_application.Name }}</span>
                </v-list-item-title>
              </template>

              {{ app.erp_application.Name }}
            </v-tooltip>
            <v-tooltip top open-delay="1000">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-subtitle
                  v-bind="attrs"
                  v-on="on"
                  :class="allowSelection ? 'ml-5' : ''"
                >
                  {{ app.erp_application.Filter }}
                </v-list-item-subtitle>
              </template>
              {{ app.erp_application.Filter }} {{ app.erp_application.id }}
            </v-tooltip>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-alert>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
export default {
  props: {
    variant: {
      type: Object,
      required: true,
    },
    selectedErpApplicationIds: {
      type: Array,
      required: false,
    },
    allowSelection: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      newSelection: [],
    };
  },
  watch: {
    selectedErpApplicationIds: {
      handler() {
        if (this.selectedErpApplicationIds?.length > 0) {
          this.newSelection = this.selectedErpApplicationIds;
        } else {
          this.newSelection = this.erpapplicationVariant.map(
            (app) => app.erp_application.id
          );
        }
      },
      immediate: true,
      deep: true,
    },
    erpapplicationVariant() {
      if (this.selectedErpApplicationIds?.length > 0) {
        this.newSelection = this.selectedErpApplicationIds;
      } else {
        this.newSelection = this.erpapplicationVariant.map(
          (app) => app.erp_application.id
        );
      }
    },
  },
  mixins: [
    makeFindMixin({
      service: 'erpapplication-variant',
      watch: 'variant.id',
    }),
  ],
  methods: {
    toggleSelection(app) {
      if (this.allowSelection) {
        if (this.newSelection.includes(app.erp_application.id)) {
          this.newSelection = this.newSelection.filter(
            (id) => id !== app.erp_application.id
          );
        } else {
          this.newSelection.push(app.erp_application.id);
        }
        this.$emit('updateSelectedErpApplicationIds', this.newSelection);
      }
    },
  },
  computed: {
    erpapplicationVariantParams() {
      const query = {
        VariantId: this.variant.id,
      };

      if (!this.allowSelection && this.selectedErpApplicationIds?.length > 0) {
        query.ErpApplicationId = { $in: this.selectedErpApplicationIds };
      }

      return { query };
    },
  },
};
</script>

<style lang="scss" scoped></style>
