<template>
  <div>
    <v-progress-linear
      v-if="isFindTestScenarioRunDetailPending"
      indeterminate
      color="primary"
    ></v-progress-linear>
    <ejs-schedule
      ref="scheduler"
      :eventSettings="eventSettings"
      :group="group"
      :timeScale="timeScale"
      currentView="Month"
    >
      <e-resources>
        <e-resource
          field="resourceId"
          title="department"
          name="department"
          :allowMultiple="false"
          :dataSource="department"
          textField="Name"
          idField="id"
          colorField="Color"
        >
        </e-resource>
      </e-resources>
    </ejs-schedule>
  </div>
</template>

<script>
import {
  ScheduleComponent,
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda,
  ResourcesDirective,
  ResourceDirective,
  TimelineMonth,
} from '@syncfusion/ej2-vue-schedule';

import { makeFindMixin } from 'feathers-vuex';
export default {
  components: {
    'ejs-schedule': ScheduleComponent,
    'e-resources': ResourcesDirective,
    'e-resource': ResourceDirective,
  },
  provide: {
    schedule: [Day, Week, WorkWeek, Month, Agenda, TimelineMonth],
  },
  mixins: [
    makeFindMixin({
      service: 'test-scenario-run-detail',
      watch: 'testRunIds',
    }),
    makeFindMixin({
      service: 'test-scenario-run',
    }),
  ],
  data() {
    return {
      eventSettings: {
        dataSource: null,
      },
      timeScale: {
        enable: false,
      },
      group: {
        resources: ['department'],
      },
      department: [],
    };
  },
  watch: {
    isFindTestScenarioRunDetailPending: {
      handler() {
        if (!this.isFindTestScenarioRunDetailPending) {
          this.eventSettings = {
            // Reassign object to trigger Vue reactivity
            ...this.eventSettings,
            dataSource: this.plannedTests,
          };
          this.department = this.uniqueDepartments;

          this.$nextTick(() => {
            this.$refs.scheduler.refreshEvents();
          });
        }
      },
      deep: true,
    },
  },

  computed: {
    testScenarioRunParams() {
      return { query: { Done: false } };
    },
    testRunIds() {
      return this.testScenarioRun
        .filter((f) => f.currentTest.DueDateMax != null)
        .map((f) => f.id);
    },
    testScenarioRunDetailParams() {
      return { query: { TestScenarioRunId: { $in: this.testRunIds } } };
    },
    grouped() {
      let testRuns = this.testScenarioRun.filter(
        (f) => f.currentTest.DueDateMax != null
      );

      testRuns = testRuns.map((f) => {
        return {
          ...f,
          lines: this.testScenarioRunDetail.filter(
            (detail) =>
              detail.TestScenarioRunId === f.id &&
              detail.DueDate != null &&
              detail.AllDone === false
          ),
        };
      });
      return testRuns.flatMap((testRun) =>
        testRun.lines
          .reduce((acc, line) => {
            const deptId = line.department.id;

            let existingEntry = acc.find(
              (entry) => entry.departmentId === deptId
            );

            if (!existingEntry) {
              existingEntry = {
                testRunName: testRun.Name,
                testRunNumber: testRun.id,
                departmentId: deptId,
                departmentName: line.department.Name,
                lines: [],
              };
              acc.push(existingEntry);
            }

            existingEntry.lines.push({
              lineName: line.Name,
              lineNumber: line.Order,
              dueDate: line.DueDate,
            });

            return acc;
          }, [])
          .map((entry) => ({
            testRunName: entry.testRunName,
            testRunNumber: entry.testRunNumber,
            departmentId: entry.departmentId,
            departmentName: entry.departmentName,
            lines: entry.lines,
          }))
      );
    },

    plannedTests() {
      return this.grouped.map((detail) => {
        const dueDate = new Date(detail.lines[0].dueDate);
        // const startTime = dueDate.toISOString().split('T')[0];
        // const endTime = dueDate.toISOString().split('T')[0];
        const startTime = new Date(dueDate);
        startTime.setHours(8, 0, 0, 0); // Set start time to 8 AM
        const endTime = new Date(startTime);
        endTime.setHours(endTime.getHours() + 1); // Add 1 hour to endTime

        let Description = detail.lines
          .map((line) => {
            return `${line.lineNumber} - ${line.lineName}`;
          })
          .join('\n');
        return {
          Id: detail.id,
          Subject: `${detail.testRunNumber} - ${detail.testRunName}`,
          StartTime: startTime,
          EndTime: endTime,
          isAllDay: true,
          Description,
          resourceId: detail.departmentId,
        };
      });
    },

    uniqueDepartments() {
      const departments = this.testScenarioRunDetail
        .filter((f) => f.DueDate != null)
        .map((detail) => detail.department);
      const uniqueDepartments = [
        ...new Set(departments.map((dept) => JSON.stringify(dept))),
      ].map((dept) => JSON.parse(dept));
      return uniqueDepartments.map((dept, index) => ({
        ...dept,
        Color: this.getRandomColor(index),
      }));
    },
  },
  methods: {
    getRandomColor(index) {
      const colors = [
        '#1abc9c',
        '#3498db',
        '#9b59b6',
        '#e74c3c',
        '#f39c12',
        '#d35400',
      ];
      return colors[index % colors?.length];
    },
  },
};
</script>

<style lang="scss" scoped></style>
