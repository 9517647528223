<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="testCase"
      dense
      disable-pagination
      hide-default-footer
    >
      <template v-slot:top>
        <v-row>
          <v-col class="d-flex justify-end">
            <v-btn
              v-if="$can('create', 'test-case')"
              elevation="0"
              class="my-2"
              fab
              small
              color="primary"
              @click="addItem = true"
            >
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template
        v-if="$can('delete', 'test-case')"
        v-slot:[`item.Actions`]="{ item }"
      >
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:[`item.Name`]="{ item, index }">
        <span @click="toggleEditItem(index)" v-if="!editItem[index]">
          {{ item.Name }}
        </span>
        <v-text-field
          v-else
          v-model="item.Name"
          ref="textField"
          outlined
          dense
          hide-details
          @blur="saveEdit(item, index)"
        ></v-text-field>
      </template>
      <template v-slot:[`item.Usage`]="{ item }">
        <v-tooltip v-if="item.Usage?.length || 0 > 0" bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ item.Usage?.length || 0 }}</span>
          </template>
          <ul>
            <li v-for="use in item.Usage" :key="use.id">
              {{ use.Number }}. {{ use.Name }}
            </li>
          </ul>
        </v-tooltip>
        <span v-else>0</span>
      </template>
    </v-data-table>
    <Modal
      v-if="addItem"
      title="Add Test Case"
      @cancel="addItem = false"
      @confirm="saveTestCase()"
    >
      <v-text-field
        v-model="newTestCase.Name"
        label="Name"
        outlined
        dense
        hide-details
      ></v-text-field>
    </Modal>
  </div>
</template>

<script>
import { handleErrorResponse } from '@/utils/MessageHandler';
import { makeFindMixin } from 'feathers-vuex';
import Modal from '../general/Modal.vue';

export default {
  components: {
    Modal,
  },
  props: {
    Department: {
      type: Object,
      required: true,
    },
  },
  mixins: [
    makeFindMixin({
      service: 'test-case',
      watch: 'Department.id',
    }),
  ],
  data() {
    return {
      addItem: false,
      editItem: [],
      newTestCase: {},
      headers: [
        {
          text: 'Number',
          align: 'start',
          sortable: false,
          value: 'id',
          width: '100px',
        },
        { text: 'Name', value: 'Name' },
        { text: 'Usage', value: 'Usage' },
        { text: 'Actions', value: 'Actions', width: '100px' },
      ],
    };
  },
  computed: {
    testCaseParams() {
      return {
        query: {
          DepartmentId: this.Department.id,
        },
      };
    },
  },
  methods: {
    toggleEditItem(index) {
      this.$set(this.editItem, index, !this.editItem[index]);
      if (this.editItem[index]) {
        this.$nextTick(() => {
          this.$refs.textField.focus();
        });
      }
    },
    async deleteItem(item) {
      try {
        await item.remove();
      } catch (error) {
        handleErrorResponse(error);
      }
    },
    async saveTestCase() {
      const { TestCase } = this.$FeathersVuex.api;
      const newTestCase = new TestCase();
      newTestCase.Name = this.newTestCase.Name;
      newTestCase.DepartmentId = this.Department.id;
      try {
        await newTestCase.save();
        this.newTestCase = {};
        this.addItem = false;
      } catch (error) {
        handleErrorResponse(error);
      }
    },
    async saveEdit(item, index) {
      try {
        await item.save();
        this.$set(this.editItem, index, false);
      } catch (error) {
        handleErrorResponse(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
