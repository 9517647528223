<template>
  <div>
    <v-progress-circular
      v-if="isPending"
      indeterminate
      :size="15"
      :width="1"
    ></v-progress-circular>
    <div v-if="!isPending">
      <ul>
        <li v-for="department in departmentList" :key="department.id">
          {{ department.Name }}
          <ul>
            <li v-for="testCase in department.test_cases" :key="testCase.id">
              {{ testCase.Name }}
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
export default {
  props: {
    testScenarioHeader: {
      type: Object,
      required: false,
    },
  },
  mixins: [
    makeFindMixin({
      service: 'test-scenario-header-test-case',
      watch: 'testScenarioHeader.id',
    }),
  ],
  computed: {
    isPending() {
      return this.isFindTestSceanrioHeaderTestCasePending;
    },

    testScenarioHeaderTestCaseParams() {
      return {
        query: {
          TestScenarioHeaderId: this.testScenarioHeader.id,
        },
      };
    },
    departmentList() {
      const departments = {};

      this.testScenarioHeaderTestCase.forEach((item) => {
        if (!item.test_case) {
          return;
        }
        const dept = item.test_case.department;
        const deptId = dept.id;

        if (!departments[deptId]) {
          departments[deptId] = {
            id: dept.id,
            Name: dept.Name,
            CustomerDepartment: dept.CustomerDepartment,
            test_cases: [],
          };
        }

        departments[deptId].test_cases.push({
          id: item.test_case.id,
          Name: item.test_case.Name,
        });
      });

      return Object.values(departments);
    },
  },
};
</script>

<style lang="scss" scoped></style>
