<template>
  <v-form ref="form" v-model="validInput">
    <v-row
      ><v-col cols="12" md="12"
        >Reschedule run <b>{{ planRunItem?.id }}</b> for '<b>{{
          planRunItem ? planRunItem.Name : ''
        }}</b
        >'</v-col
      ></v-row
    >
    <div class="mt-5 mb-1" v-if="TestData.length > 1">
      <b>Global Reschedule</b>
      <v-row align="center"
        ><v-col cols="12" md="3">
          <v-menu
            v-model="showMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="
                  globalDueDate
                    ? new Date(globalDueDate).toLocaleDateString()
                    : null
                "
                label="Due Date"
                prepend-inner-icon="mdi-calendar"
                readonly
                outlined
                dense
                hide-details
                clearable
                v-bind="attrs"
                v-on="on"
                @click:clear="globalDueDate = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="globalDueDate"
              no-title
              locale="nl-be"
              color="primary"
              header-color="primary"
              @input="showMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="2"
          ><v-btn outlined @click="fillDate">Fill Date</v-btn></v-col
        >
        <v-col cols="12" md="5">
          <v-autocomplete
            v-model="globalAssignee"
            :items="users"
            item-text="fullname"
            item-value="id"
            label="Assignees"
            clearable
            hide-details
            outlined
            dense
            v-bind:search-input.sync="searchUser"
            @input="clearSearch"
            ><template v-slot:item="{ item }">
              <UserAvatar
                :Name="item.fullname"
                :Image="item.gravatarUrl"
                :Color="item.color"
              />
              {{ item.fullname }}</template
            ><template #selection="{ item }">
              <UserAvatar
                :Name="item.fullname"
                :Image="item.gravatarUrl"
                :Color="item.color"
              />
              {{
                item.fullname.length > 16
                  ? item.fullname.substring(0, 16) + '..'
                  : item.fullname
              }}
            </template></v-autocomplete
          ></v-col
        >
        <v-col cols="12" md="2"
          ><v-btn outlined @click="fillUser">Fill User</v-btn></v-col
        >
      </v-row>
    </div>
    <div class="mt-5 mb-1">
      <b>Reschedule per Department</b>
      <v-checkbox
        v-model="syncDepartments"
        label="Sync departments"
        dense
        hide-details
      ></v-checkbox>
    </div>
    <v-row
      ><v-col>
        <v-expansion-panels accordion v-model="activePanels" multiple>
          <v-expansion-panel
            :readonly="true"
            v-for="(department, index) in TestData"
            :key="index"
          >
            <v-expansion-panel-header>
              <template v-slot:actions
                ><span
                  class="text-caption"
                  :style="{
                    width: '30px',
                  }"
                  >({{ department.testLines.length }})</span
                >

                <v-icon @click.stop="togglePanel(index)" color="primary">
                  $expand
                </v-icon>
              </template>
              <v-row align="center"
                ><v-col cols="12" md="3">
                  <span>{{ department.name }}</span></v-col
                ><v-col cols="12" md="3">
                  <v-menu
                    v-model="department.showMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="
                          department.DueDate
                            ? new Date(department.DueDate).toLocaleDateString()
                            : null
                        "
                        label="Due Date"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        hide-details
                        clearable
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="department.DueDate = null"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="department.DueDate"
                      no-title
                      locale="nl-be"
                      color="primary"
                      header-color="primary"
                      @input="department.showMenu = false"
                      @change="multipleUpdateDueDate(department, index, 'date')"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="5">
                  <v-autocomplete
                    v-model="department.Assignee"
                    :items="users"
                    item-text="fullname"
                    item-value="id"
                    label="Assignees"
                    clearable
                    hide-details
                    outlined
                    dense
                    v-bind:search-input.sync="searchUser"
                    @input="clearSearch"
                    @change="
                      multipleUpdateDueDate(department, index, 'assignee')
                    "
                    ><template v-slot:item="{ item }">
                      <UserAvatar
                        :Name="item.fullname"
                        :Image="item.gravatarUrl"
                        :Color="item.color"
                      />
                      {{ item.fullname }}</template
                    ><template #selection="{ item }">
                      <UserAvatar
                        :Name="item.fullname"
                        :Image="item.gravatarUrl"
                        :Color="item.color"
                      />
                      {{
                        item.fullname.length > 16
                          ? item.fullname.substring(0, 16) + '..'
                          : item.fullname
                      }}
                    </template></v-autocomplete
                  ></v-col
                >
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ul>
                <li v-for="(line, index) in department.testLines" :key="index">
                  {{ line.Name }}
                </li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col></v-row
    >
    <v-checkbox
      v-if="similarRunIds.length > 0"
      v-model="syncSimilarTests"
      :label="`Sync due dates on similar test runs ${similarRunIds.join(
        ', '
      )}?`"
      hide-details
      dense
    ></v-checkbox>
  </v-form>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import { handleErrorResponse } from '@/utils/MessageHandler';
import UserAvatar from '@/components/general/UserAvatar.vue';

export default {
  props: {
    planRunItem: {
      type: Object,
      required: true,
    },
  },
  components: {
    UserAvatar,
  },
  mixins: [
    makeFindMixin({
      service: 'test-scenario-run-detail',
      watch: 'planRunItem.id',
    }),
    makeFindMixin({
      service: 'test-scenario-run',
      watch: 'testScenarioRunDetail.TestScenarioHeaderId',
    }),
    makeFindMixin({ service: 'department' }),
    makeFindMixin({ service: 'users' }),
  ],
  watch: {
    isFindTestScenarioRunDetailPending: {
      handler() {
        if (!this.isFindTestScenarioRunDetailPending) {
          const testRunLines = this.testScenarioRunDetail.filter(
            (f) => f.AllDone == false
          );
          if (testRunLines.length === 0) return [];

          let result = [];
          let currentDepartmentId = null;
          let currentGroup = null;

          for (const line of testRunLines) {
            const departmentId = line.department.id;

            if (departmentId !== currentDepartmentId) {
              if (currentGroup) {
                result.push(currentGroup);
              }
              currentGroup = {
                id: departmentId,
                name: this.department.find((f) => f.id == departmentId)?.Name,
                DueDate: line.DueDate,
                Assignee: line.AssignedUserId,
                testLines: [],
              };
              currentDepartmentId = departmentId;
            }

            currentGroup.testLines.push(line);
          }

          // Push the last group
          if (currentGroup) {
            result.push(currentGroup);
          }

          this.TestData = result;
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      validInput: true,

      TestData: [],
      dateMenu: [],
      showProgress: false,
      activePanels: [],
      searchUser: '',
      syncDepartments: true,
      showMenu: false,
      globalDueDate: null,
      globalAssignee: null,
      syncSimilarTests: false,
      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value?.length <= 20 || 'Max 20 characters',
        number: (value) => !isNaN(value) || 'Must be a number',
      },
    };
  },
  computed: {
    testScenarioRunDetailParams() {
      return {
        query: {
          TestScenarioRunId: this.planRunItem.id,

          $sort: { Order: 1 },
        },
      };
    },
    departmentParams() {
      return {};
    },
    usersParams() {
      return { query: { Active: true, $sort: { fullname: 1 } } };
    },
    testScenarioRunParams() {
      return {
        query: {
          TestScenarioHeaderId: this.planRunItem.TestScenarioHeaderId,
          $sort: { Order: 1 },
        },
      };
    },
    similarRunIds() {
      const testRunLines = this.testScenarioRun.filter(
        (f) =>
          f.currentTest.DueDate == this.planRunItem.currentTest.DueDate &&
          f.currentTest.DueDateMax == this.planRunItem.currentTest.DueDateMax &&
          f.id !== this.planRunItem.id
      );

      if (testRunLines.length === 0) return [];

      return testRunLines.map((m) => m.id);
    },
  },
  methods: {
    togglePanel(index) {
      const panelIndex = this.activePanels.indexOf(index);
      if (panelIndex === -1) {
        this.activePanels.push(index); // Open panel
      } else {
        this.activePanels.splice(panelIndex, 1); // Close panel
      }
    },
    clearSearch() {
      this.searchUser = '';
    },
    formatDueDate(date) {
      if (!date) return ''; // Handle empty case

      return new Date(date).toLocaleDateString('nl-BE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
    },
    multipleUpdateDueDate(department, index, type) {
      if (this.syncDepartments) {
        if (type == 'date') {
          for (const depLine of this.TestData.filter(
            (f, i) => f.id == department.id && i > index
          )) {
            depLine.DueDate = department.DueDate;
          }
        } else if (type == 'assignee') {
          for (const depLine of this.TestData.filter(
            (f, i) => f.id == department.id && i > index
          )) {
            depLine.Assignee = department.Assignee;
          }
        }
      }
    },
    fillDate() {
      for (const depLine of this.TestData) {
        depLine.DueDate = this.globalDueDate;
      }
    },
    fillUser() {
      for (const depLine of this.TestData) {
        depLine.Assignee = this.globalAssignee;
      }
    },

    async reScheduleRuns() {
      this.$emit('scheduleRun', true);
      for (const depLine of this.TestData) {
        for (const line of depLine.testLines) {
          line.DueDate = depLine.DueDate;
          line.AssignedUserId = depLine.Assignee;
          try {
            await line.save();
          } catch (error) {
            handleErrorResponse(error);
          }
        }
      }

      if (this.syncSimilarTests) {
        await this.updateSimilarTests();
      }

      this.$emit('scheduleRun', false);
      this.$emit('closeDialog', false);
      // location.reload();
    },
    async updateSimilarTests() {
      for (const testRun of this.testScenarioRun.filter((f) =>
        this.similarRunIds.includes(f.id)
      )) {
        const { TestScenarioRunDetail } = this.$FeathersVuex.api;
        const linesToUpdate = await TestScenarioRunDetail.find({
          query: { TestScenarioRunId: testRun.id },
        });

        // flatten TestData testlines
        const flattenedTestLines = this.TestData.reduce(
          (acc, curr) => acc.concat(curr.testLines),
          []
        );

        for (const line of linesToUpdate.data) {
          line.DueDate =
            flattenedTestLines.find(
              (testLine) =>
                testLine.TestScenarioLineId === line.TestScenarioLineId
            )?.DueDate || line.DueDate;

          try {
            await line.save();
          } catch (error) {
            handleErrorResponse(error);
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
