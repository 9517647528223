<template>
  <div>
    Test Remark:
    <span v-if="testScenarioRunSteps">{{ testScenarioRunSteps.Remark }}</span>
    <TestScenarioLineStepFields
      :testScenarioLineStep="testScenarioRunSteps?.test_scenario_line_step"
      :keyReload="testScenarioRunSteps.id"
    />
    <span
      v-html="testScenarioRunSteps.process_step.Description"
      class="ck-content"
    ></span>
  </div>
</template>

<script>
import TestScenarioLineStepFields from '@/components/TestScenarios/TestScenarioHeader/TestScenarioLineStepField.vue';

export default {
  components: {
    TestScenarioLineStepFields,
  },
  props: {
    // loading: {
    //   type: Boolean,
    //   required: true,
    // },
    // testScenarioRun: {
    //   type: Array,
    //   required: true,
    // },
    // testScenarioRunDetail: {
    //   type: Array,
    //   required: true,
    // },
    testScenarioRunSteps: {
      type: Object,
      required: false,
    },
  },
};
</script>
