<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="testCases"
      item-key="id"
      dense
      disable-pagination
      hide-default-footer
    >
      <template v-slot:top>
        <v-row>
          <v-col class="d-flex justify-end">
            <v-btn
              v-if="$can('create', 'test-scenario-header-test-case')"
              elevation="0"
              class="my-2"
              fab
              x-small
              color="primary"
              @click="addItem = true"
            >
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template
        v-if="$can('delete', 'test-scenario-header-test-case')"
        v-slot:[`item.Actions`]="{ item }"
      >
        <v-icon small @click="deleteTestCase(item)"> mdi-delete </v-icon>
      </template></v-data-table
    >
    <Modal
      v-if="addItem"
      title="Add Test Case"
      @cancel="addItem = false"
      @confirm="saveTestCase()"
    >
      <v-autocomplete
        v-model="newTestCase"
        :items="testCase"
        :item-text="testCaseName"
        item-value="id"
        label="Test Case"
        outlined
        dense
        return-object
        hide-details
        small-chips
        multiple
      ></v-autocomplete>
    </Modal>
  </div>
</template>

<script>
import { handleErrorResponse } from '@/utils/MessageHandler';
import { makeFindMixin } from 'feathers-vuex';
import Modal from '@/components/general/Modal.vue';

export default {
  components: {
    Modal,
  },
  props: {
    testCases: {
      type: Array,
      required: true,
    },
    testScenarioHeaderId: {
      type: Number,
      required: true,
    },
    departmentId: {
      type: Number,
      required: true,
    },
  },
  mixins: [
    makeFindMixin({
      service: 'test-case',
      watch: 'Department.id',
    }),
  ],

  data() {
    return {
      headers: [
        { text: 'Name', value: 'test_case.Name' },
        { text: 'Actions', value: 'Actions', width: '100px' },
      ],
      addItem: false,
      newTestCase: [],
    };
  },
  methods: {
    testCaseName(testCase) {
      return `${testCase.Name} (${testCase.Usage?.length})`;
    },
    async deleteTestCase(item) {
      try {
        await item.remove();
      } catch (error) {
        handleErrorResponse(error);
      }
    },
    async saveTestCase() {
      const { TestScenarioHeaderTestCase } = this.$FeathersVuex.api;
      for (const test of this.newTestCase) {
        let newTestCase = new TestScenarioHeaderTestCase();
        newTestCase.TestScenarioHeaderId = this.testScenarioHeaderId;
        newTestCase.TestCaseId = test.id;
        try {
          await newTestCase.save();
        } catch (error) {
          handleErrorResponse(error);
        }
      }
      this.newTestCase = [];
      this.addItem = false;
      this.$emit('reload');
    },
  },
  computed: {
    existingTestCasesIds() {
      return this.testCases.map((tc) => tc.TestCaseId);
    },
    testCaseParams() {
      return {
        query: {
          DepartmentId: this.departmentId,
          $sort: { Name: 1 },
          id: { $nin: this.existingTestCasesIds },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
