var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"value":true,"persistent":"","max-width":"1100px","retain-focus":false}},[(_vm.isFindStatusPending)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"secondary"}}):_vm._e(),(_vm.item && !_vm.isFindStatusPending)?_c('div',[_c('v-form',{ref:"form",model:{value:(_vm.validInput),callback:function ($$v) {_vm.validInput=$$v},expression:"validInput"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("New/Edit Comment")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_vm._v(" Comment"),_c('RichEditor',{attrs:{"disabled":!(
                      _vm.$can('update', _vm.item, 'Comment') ||
                      _vm.$can('create', 'comment')
                    ),"placeholder":"Comment"},model:{value:(_vm.item.Comment),callback:function ($$v) {_vm.$set(_vm.item, "Comment", $$v)},expression:"item.Comment"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"disabled":!(
                      _vm.$can('update', _vm.item, 'StatusId') ||
                      _vm.$can('create', 'comment')
                    ),"items":_vm.filteredStatus,"item-text":"Status","item-value":"id","label":"Status","outlined":"","rules":[_vm.rules.required],"dense":""},model:{value:(_vm.item['StatusId']),callback:function ($$v) {_vm.$set(_vm.item, 'StatusId', $$v)},expression:"item['StatusId']"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"disabled":!(
                      _vm.$can('update', _vm.item, 'StatusId') ||
                      _vm.$can('create', 'comment')
                    ),"items":_vm.users,"item-text":"fullname","item-value":"id","label":"Assigned User","outlined":"","dense":""},model:{value:(_vm.item['AssignedUserId']),callback:function ($$v) {_vm.$set(_vm.item, 'AssignedUserId', $$v)},expression:"item['AssignedUserId']"}})],1),_c('v-checkbox',{attrs:{"disabled":!_vm.$can('update', _vm.item, 'ForCustomer'),"label":`Comment for Customer`},model:{value:(_vm.item.ForCustomer),callback:function ($$v) {_vm.$set(_vm.item, "ForCustomer", $$v)},expression:"item.ForCustomer"}})],1)],1),_c('v-card-actions',[(_vm.$can('delete', 'comment-reply'))?_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){_vm.showRemoveMessage = true}}},[_vm._v("Remove")]):_vm._e(),(_vm.showRemoveMessage)?_c('div',{staticClass:"ml-5"},[_vm._v(" Are you sure? "),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"error"},on:{"click":function($event){return _vm.$emit('remove')}}},[_vm._v("Yes")])],1):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"disabled":_vm.item.Comment.length == 0,"loading":_vm.saving,"color":"primary"},on:{"click":function($event){return _vm.$emit('save')}}},[_vm._v(" Save ")])],1)],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }