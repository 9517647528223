<template>
  <div>
    <v-dialog :value="true" persistent max-width="90vw" :retain-focus="false">
      <v-card>
        <v-card-title class="text-h5"> AI Response </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-card
                ><v-card-text
                  ><span
                    v-html="originalItem.Description"
                    class="ck-content"
                  ></span></v-card-text
              ></v-card>
            </v-col>
            <v-col cols="12" md="6">
              <RichEditor
                v-model="correctedResponse"
                :disabled="!$can('update', originalItem, 'Description')"
                @imageUploadProgress="imageUploadProgress = $event"
            /></v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="$emit('cancel')">Cancel</v-btn>
          <v-btn
            color="primary"
            :loading="isUploadingImage"
            @click="$emit('confirm', correctedResponse)"
          >
            OK
            <template v-slot:loader>
              <v-progress-circular
                color="white"
                :rotate="-90"
                :value="imageUploadProgress"
              ></v-progress-circular>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import RichEditor from '@/components/general/RichEditor.vue';
export default {
  data() {
    return { correctedResponse: '', imageUploadProgress: 0 };
  },
  props: {
    originalItem: {
      type: Object,
      required: true,
    },
    gptResponse: {
      type: String,
      required: false,
    },
  },
  computed: {
    isUploadingImage() {
      return this.imageUploadProgress > 0 && this.imageUploadProgress < 100;
    },
  },
  components: { RichEditor },
  watch: {
    gptResponse: function () {
      this.correctedResponse = this.gptResponse;
    },
  },
};
</script>

<style lang="scss" scoped></style>
