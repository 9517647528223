var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{attrs:{"color":_vm.user.vendor.ResponsePhase == 1
        ? _vm.editItem.Final
          ? '#C7FFBF'
          : _vm.editItem.ImplementationId && _vm.editItem.ComplexityId
          ? '#FFFCBD'
          : '#FFCDD2'
        : '#F5F5F5'}},[(_vm.variant.Input)?_c('v-card-text',{staticClass:"ck-content",domProps:{"innerHTML":_vm._s(_vm.variant.Input)}}):_vm._e(),(_vm.variant.Output)?_c('v-card-text',{staticClass:"ck-content",domProps:{"innerHTML":_vm._s(_vm.variant.Output)}}):_vm._e(),_c('v-card-title',[_vm._v("Response")]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"disabled":_vm.user.vendor.ResponsePhase != 1}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.implementation,"item-text":"Name","item-value":"id","hide-details":"","label":"Implementation","disabled":!_vm.$can('update', 'response') ||
                _vm.editItem.Final ||
                (_vm.vendor[0] ? _vm.vendor[0].SendInDate != null : true),"outlined":"","dense":"","clearable":""},on:{"blur":function($event){return _vm.updateResponse()}},model:{value:(_vm.editItem.ImplementationId),callback:function ($$v) {_vm.$set(_vm.editItem, "ImplementationId", $$v)},expression:"editItem.ImplementationId"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.filteredComplexity(_vm.editItem.ImplementationId),"item-text":"Name","item-value":"id","hide-details":"","label":"Complexity","disabled":!_vm.$can('update', 'response') ||
                _vm.editItem.Final ||
                (_vm.vendor[0] ? _vm.vendor[0].SendInDate != null : true),"outlined":"","dense":"","clearable":""},on:{"change":function($event){return _vm.updateResponse()}},model:{value:(_vm.editItem.ComplexityId),callback:function ($$v) {_vm.$set(_vm.editItem, "ComplexityId", $$v)},expression:"editItem.ComplexityId"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-textarea',{attrs:{"label":"Remark","hide-details":"","disabled":!_vm.$can('update', 'response') ||
                _vm.editItem.Final ||
                (_vm.vendor[0] ? _vm.vendor[0].SendInDate != null : true),"outlined":"","dense":"","rows":"1","auto-grow":"","clearable":""},on:{"blur":function($event){return _vm.updateResponse()}},model:{value:(_vm.editItem.Remark),callback:function ($$v) {_vm.$set(_vm.editItem, "Remark", $$v)},expression:"editItem.Remark"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-checkbox',{attrs:{"hide-details":"","disabled":!_vm.$can('update', 'response') ||
                (_vm.vendor[0] ? _vm.vendor[0].SendInDate != null : true),"label":`Final`,"dense":""},on:{"click":function($event){return _vm.updateResponse(true)}},model:{value:(_vm.editItem.Final),callback:function ($$v) {_vm.$set(_vm.editItem, "Final", $$v)},expression:"editItem.Final"}})],1)],1)],1)],1)],1),_c('v-divider'),_c('ProcessSteps',{attrs:{"variant":_vm.variant}}),_c('v-overlay',{attrs:{"opacity":"0","value":_vm.saving}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64","color":"#000000"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }