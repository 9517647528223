import { render, staticRenderFns } from "./RichEditor.vue?vue&type=template&id=de1c7f68&scoped=true"
import script from "./RichEditor.vue?vue&type=script&lang=js"
export * from "./RichEditor.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de1c7f68",
  null
  
)

export default component.exports