<template>
  <div class="ma-5">
    <div ref="supersetContainer" class="BI"></div>
  </div>
</template>

<script>
import { embedDashboard } from '@superset-ui/embedded-sdk';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      guestToken: null,
    };
  },
  watch: {
    isBIEnabled: {
      handler: async function (newVal) {
        if (newVal === true) {
          this.guestToken = await this.fetchGuestToken();
          if (this.guestToken) {
            this.loadDashboard();
          }
        }
      },
      immediate: true,
    },
  },

  computed: {
    ...mapGetters([
      'isBIEnabled',
      'BIUsername',
      'BIPassword',
      'BIUrl',
      'BIDashboardIds',
    ]),
  },
  methods: {
    async fetchGuestToken() {
      try {
        const response = await fetch(
          `${this.$appConfig.apiBaseUrl}/superset-token`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${window.localStorage.getItem(
                'feathers-jwt'
              )}`,
            },
            body: JSON.stringify({
              username: this.BIUsername,
              password: this.BIPassword,
              dashboardId: this.BIDashboardIds[0],
            }),
          }
        );

        const data = await response.json();

        if (response.ok) {
          return data.token;
        } else {
          console.error('Error response from server:', data);
          return null;
        }
      } catch (error) {
        console.error('Error fetching guest token:', error);
      }
    },
    loadDashboard() {
      embedDashboard({
        id: this.BIDashboardIds[0],
        supersetDomain: this.BIUrl,
        mountPoint: this.$refs.supersetContainer,
        fetchGuestToken: () => Promise.resolve(this.guestToken),
        dashboardUiConfig: {
          hideTitle: true,
          filters: { expanded: false },
        },
      })
        .then(() => {
          console.log('Dashboard loaded successfully.');
        })
        .catch((error) => {
          console.error('Error loading dashboard:', error);
        });
    },
  },
};
</script>

<style>
.BI iframe {
  border: none;
  width: 100% !important;
  height: 96vh !important;
}
</style>
