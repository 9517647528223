const config = {
  apiBaseUrl: parse(process.env.VUE_APP_API_BASE_URL, 'http://localhost:3030'),
  clientBaseUrl: parse(
    process.env.VUE_APP_CLIENT_BASE_URL,
    'http://localhost:8080'
  ),
  docExportUrl: parse(
    process.env.VUE_APP_DOC_EXPORT_URL,
    'https://doc-export.loxi.be'
    // 'http://localhost:5062'
  ),
  customer: {
    // title: parse(process.env.VUE_APP_CUSTOMER_TITLE, 'Loxi Dev'),
    logo: parse(process.env.VUE_APP_CUSTOMER_LOGO, 'ToyChamp_Champy.svg'),
    logoLarge: parse(process.env.VUE_APP_CUSTOMER_LOGOLARGE, 'LeahDark.png'),
    prefix: parse(process.env.VUE_APP_CUSTOMER_PREFIX, 'DEV'),
    home: parse(process.env.VUE_APP_CUSTOMER_HOME, 'process'),
    imageType: parse(process.env.VUE_APP_CUSTOMER_IMAGETYPE, 'lucid'), // svg, png, lucid, drawio
    imagePrefix: parse(process.env.VUE_APP_CUSTOMER_IMAGEPREFIX, true), // is image prefixed by the customerprefix
    customPDF: parse(process.env.VUE_APP_CUSTOMPDF, ''),
    lucidParentFolderId: parse(
      process.env.VUE_APP_LUCIDPARENTFOLDERID,
      '323592551'
    ),
  },
  S3: {
    apiVersion: 'latest',
    bucket: 'loxi',
    endpoint: 'https://eu-central-1.linodeobjects.com/',
    credentials: {
      accessKeyId: 'CTU2NNRJ5G0O0JBAPI5L',
      secretAccessKey: 'AV6VlTtqrCOm3ix24jnjfJO8Ahyxw6X7Y9FKgMbE',
    },
  },
  devOps: {
    organisation: parse(process.env.VUE_APP_DEVOPS_ORGANISATION, 'Foocus'),
    project: parse(process.env.VUE_APP_DEVOPS_PROJECT, 'FoocusManager'),
  },
  Jira: {
    organisation: parse(process.env.VUE_APP_JIRA_ORGANISATION, 'foocus'),
    cloudId: parse(
      process.env.VUE_APP_JIRA_CLOUDID,
      'e6cfc633-684f-4555-948d-53b12867b3c9'
    ),
    project: parse(process.env.VUE_APP_JIRA_PROJECT, 'TEST'),
  },
  externalIssues: parse(process.env.VUE_APP_EXTERNAL_ISSUES, 'DevOps'), // Jira, DevOps
  locale: parse(process.env.VUE_APP_LOCALE, 'en'),
  version: '2.17.0',

  features: {
    specification: parse(process.env.VUE_APP_FEATURE_SPECIFICATION, true),
    erpApplication: parse(process.env.VUE_APP_ERP_APPLICATION, true),
    lucidChartIntegration: parse(
      process.env.VUE_APP_FEATURE_LUCIDCHARTINTEGRATION,
      false
    ),
    drawIOChartIntegration: parse(
      process.env.VUE_APP_FEATURE_DRAWIOCHARTINTEGRATION,
      true
    ),
    cloudinaryChartIntegration: parse(
      process.env.VUE_APP_FEATURE_CLOUDINARYCHARTINTEGRATION,
      false
    ),
    planner: parse(process.env.VUE_APP_PLANNER, true),
    // devopsIntegration: parse(
    //   process.env.VUE_APP_FEATURE_DEVOPSINTEGRATION,
    //   false
    // ),
    // jiraIntegration: parse(process.env.VUE_APP_FEATURE_JIRAINTEGRATION, true),
    testScenarios: parse(process.env.VUE_APP_TEST_SCENARIOS, true),
    budget: parse(process.env.VUE_APP_BUDGET, false),
  },
};

function feature(name) {
  return config.features[name];
}
function parse(value, fallback) {
  if (typeof value === 'undefined') {
    return fallback;
  }
  switch (typeof fallback) {
    case 'boolean':
      return !!JSON.parse(value);
    case 'number':
      return JSON.parse(value);
    default:
      return value;
  }
}
export { config };

export default {
  install(Vue) {
    Vue.appConfig = config;
    Vue.feature = feature;
    Vue.prototype.$appConfig = config;
    Vue.prototype.$feature = feature;
  },
};
