<template>
  <!-- <v-dialog :value="true" persistent max-width="800px"> -->
  <v-card flat dense class="mt-5">
    <draggable v-model="list" v-bind="dragOptions">
      <transition-group>
        <v-card elevation="1" v-for="item in list" :key="item.id" class="mb-2">
          <v-card-text class="py-0">
            {{ item.Order }}. {{ item.Name }} ({{ item.Number }})"
          </v-card-text>
        </v-card>
      </transition-group>
    </draggable>
    <v-card-actions
      ><v-spacer></v-spacer>
      <v-btn outlined @click="showReOrderDialog = false">Cancel</v-btn>
      <v-btn color="primary" @click="saveSteps()"> Save </v-btn>
    </v-card-actions>
  </v-card>
  <!-- </v-dialog> -->
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import feathersClient from '@/feathers-client';
import draggable from 'vuedraggable';
export default {
  props: ['end2endId'],
  name: 'Reorder',
  data() {
    return {
      list: [],
    };
  },
  components: { draggable },
  mixins: [makeFindMixin({ service: 'process' })],

  methods: {
    async saveSteps() {
      this.list.forEach(async (p, index) => {
        let e2e = await feathersClient
          .service('end2End_process')
          .find({ query: { processId: p.id, end2EndId: this.end2endId } });

        feathersClient
          .service('end2End_process')
          .patch(e2e.data[0].id, { Order: index + 1 });
      });
      this.showReOrderDialog = false;
    },
  },
  computed: {
    processParams() {
      return {};
    },
    showReOrderDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },
  mounted() {
    if (this.end2endId) {
      this.list = this.process.filter((item) =>
        item.end_2_ends.some((e) => e.id == this.end2endId)
      );
      this.list = this.list.map((m) => {
        return {
          ...m,
          Order: m.end_2_ends.find((e) => e.id == this.end2endId)
            .end2End_process.Order,
        };
      });
      this.list.sort((a, b) => a.Order - b.Order);
    } else {
      this.list = [];
    }
  },
};
</script>

<style></style>
