<template>
  <div>
    <v-dialog :value="true" persistent max-width="1100px" :retain-focus="false">
      <v-progress-linear
        v-if="isFindStatusPending"
        indeterminate
        color="secondary"
      ></v-progress-linear>

      <div v-if="item && !isFindStatusPending">
        <v-form ref="form" v-model="validInput">
          <v-card flat>
            <v-card-title>
              <span class="headline">New/Edit Comment</span>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12" md="12">
                  Comment<RichEditor
                    v-model="item.Comment"
                    :disabled="
                      !(
                        $can('update', item, 'Comment') ||
                        $can('create', 'comment')
                      )
                    "
                    placeholder="Comment"
                  ></RichEditor>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    v-model="item['StatusId']"
                    :disabled="
                      !(
                        $can('update', item, 'StatusId') ||
                        $can('create', 'comment')
                      )
                    "
                    :items="filteredStatus"
                    item-text="Status"
                    item-value="id"
                    label="Status"
                    outlined
                    :rules="[rules.required]"
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    v-model="item['AssignedUserId']"
                    :disabled="
                      !(
                        $can('update', item, 'StatusId') ||
                        $can('create', 'comment')
                      )
                    "
                    :items="users"
                    item-text="fullname"
                    item-value="id"
                    label="Assigned User"
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-checkbox
                  v-model="item.ForCustomer"
                  :disabled="!$can('update', item, 'ForCustomer')"
                  :label="`Comment for Customer`"
                ></v-checkbox>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                v-if="$can('delete', 'comment-reply')"
                outlined
                @click="showRemoveMessage = true"
                >Remove</v-btn
              >
              <div v-if="showRemoveMessage" class="ml-5">
                Are you sure?
                <v-btn class="ml-2" @click="$emit('remove')" color="error"
                  >Yes</v-btn
                >
              </div>
              <v-spacer></v-spacer>
              <v-btn outlined @click="$emit('cancel')">Cancel</v-btn>
              <v-btn
                :disabled="item.Comment.length == 0"
                :loading="saving"
                color="primary"
                @click="$emit('save')"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import RichEditor from '@/components/general/RichEditor.vue';

import { makeFindMixin } from 'feathers-vuex';
import { mapGetters } from 'vuex';
export default {
  name: 'ProcessCommentDialog',
  components: {
    RichEditor,
  },
  data() {
    return {
      prioriteit: ['Must', 'Should'],
      validInput: true,
      errorMessage: null,
      showRemoveMessage: false,

      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
      },
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    saving: { type: Boolean },
  },
  mixins: [
    makeFindMixin({ service: 'status' }),
    makeFindMixin({ service: 'priority' }),
    makeFindMixin({ service: 'users' }),
  ],
  watch: {
    item() {
      if (
        this.filteredStatus &&
        this.filteredStatus.length > 0 &&
        this.item.StatusId == null
      ) {
        this.item.StatusId = this.filteredStatus[0].id;
      }
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    statusParams() {
      return { query: { ActiveComment: true, $sort: { Number: 1 } } };
    },
    usersParams() {
      return { query: { Active: true, $sort: { fullname: 1 } } };
    },
    filteredStatus() {
      if (this.user.role == 'customer')
        return this.status.filter((s) => s.Role == this.user.role);
      else return this.status;
    },
    priorityParams() {
      return {};
    },
  },
  mounted() {
    if (
      this.filteredStatus &&
      this.filteredStatus.length > 0 &&
      this.item.StatusId == null
    ) {
      this.item.StatusId = this.filteredStatus[0].id;
    }
  },
};
</script>
