var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"width":"100vw"}},[_c('div',[_c('ejs-splitter',{attrs:{"id":"default-splitter"},on:{"resizeStop":_vm.onResize}},[_c('e-panes',[_c('e-pane',{attrs:{"content":"#menu","size":"15%"}}),_c('e-pane',{attrs:{"content":"#drawing","size":"40%"}}),_c('e-pane',{attrs:{"content":"#nested"}})],1)],1)],1),_c('div',{staticClass:"content-pane",attrs:{"id":"menu"}},[_c('TestRunTree',{attrs:{"loading":_vm.loading,"testScenarioRun":_vm.testScenarioRun,"testScenarioRunDetail":_vm.testScenarioRunDetail,"testScenarioRunSteps":_vm.testScenarioRunSteps}})],1),_c('div',{staticClass:"mx-5",attrs:{"id":"drawing"}},[(
        _vm.testScenarioRunDetail.find((f) => f.id == _vm.testRunDetailId)?.process
          ?.ProcessId
      )?_c('div',[_c('h3',[_vm._v(" Process Flow "),(
            _vm.testScenarioRunDetail.find((f) => f.id == _vm.testRunDetailId)
              ?.process?.Name
          )?_c('span',[_vm._v("- "+_vm._s(_vm.testScenarioRunDetail.find((f) => f.id == _vm.testRunDetailId) ?.process?.ProcessId))]):_vm._e()]),_c('DrawIO',{key:_vm.drawIoKey + 1,attrs:{"processId":_vm.testScenarioRunDetail.find((f) => f.id == _vm.testRunDetailId)?.process
            ?.ProcessId,"highlightVariantId":_vm.testScenarioRunDetail.find((f) => f.id == _vm.testRunDetailId)
            ?.test_scenario_line?.VariantId,"height":"45vh"}})],1):_vm._e(),(
        _vm.testScenarioRunDetail.find((f) => f.id == _vm.testRunDetailId)?.process
          ?.VariantId
      )?_c('div',[_c('h3',[_vm._v(" Sub Process Flow "),(
            _vm.testScenarioRunDetail.find((f) => f.id == _vm.testRunDetailId)
              ?.process?.VariantName
          )?_c('span',[_vm._v("- "+_vm._s(_vm.testScenarioRunDetail.find((f) => f.id == _vm.testRunDetailId) ?.process?.VariantName))]):_vm._e()]),_c('DrawIO',{key:_vm.drawIoKey + 2,attrs:{"variantId":_vm.testScenarioRunDetail.find((f) => f.id == _vm.testRunDetailId)?.process
            ?.VariantId,"highlightVariantId":_vm.testScenarioRunSteps.find((f) => f.id == _vm.testRunStepId)
            ?.ProcessStepId,"height":"45vh"}})],1):_vm._e()]),_c('div',{staticClass:"sticky",attrs:{"id":"nested"}},[_c('ejs-splitter',{attrs:{"orientation":"Vertical"},on:{"resizeStop":_vm.onResize}},[_c('e-panes',[_c('e-pane',{attrs:{"content":"#breadcrumb","size":"40px","resizable":false}}),_c('e-pane',{attrs:{"content":"#content"}})],1)],1)],1),_c('div',{attrs:{"id":"breadcrumb"}},[_vm._v("Nav")]),_c('div',{staticClass:"content-pane",attrs:{"id":"content"}},[_c('TestRunContent',{attrs:{"testScenarioRunSteps":_vm.testScenarioRunSteps.find((f) => f.id == _vm.testRunStepId)}}),_vm._v(" Content "+_vm._s(_vm.testRunId)+" / "+_vm._s(_vm.testRunDetailId)+" / "+_vm._s(_vm.testRunStepId)+" ")],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }