<template>
  <div width="100vw">
    <div>
      <ejs-splitter id="default-splitter" @resizeStop="onResize">
        <e-panes>
          <e-pane content="#menu" size="15%"></e-pane>
          <e-pane content="#drawing" size="40%"></e-pane>
          <e-pane content="#nested"></e-pane>
        </e-panes>
      </ejs-splitter>
    </div>

    <!-- pane contents -->
    <div id="menu" class="content-pane">
      <TestRunTree
        :loading="loading"
        :testScenarioRun="testScenarioRun"
        :testScenarioRunDetail="testScenarioRunDetail"
        :testScenarioRunSteps="testScenarioRunSteps"
      />
    </div>
    <div id="drawing" class="mx-5">
      <div
        v-if="
          testScenarioRunDetail.find((f) => f.id == testRunDetailId)?.process
            ?.ProcessId
        "
      >
        <h3>
          Process Flow
          <span
            v-if="
              testScenarioRunDetail.find((f) => f.id == testRunDetailId)
                ?.process?.Name
            "
            >-
            {{
              testScenarioRunDetail.find((f) => f.id == testRunDetailId)
                ?.process?.ProcessId
            }}</span
          >
        </h3>
        <DrawIO
          :key="drawIoKey + 1"
          :processId="
            testScenarioRunDetail.find((f) => f.id == testRunDetailId)?.process
              ?.ProcessId
          "
          :highlightVariantId="
            testScenarioRunDetail.find((f) => f.id == testRunDetailId)
              ?.test_scenario_line?.VariantId
          "
          height="45vh"
        />
      </div>
      <div
        v-if="
          testScenarioRunDetail.find((f) => f.id == testRunDetailId)?.process
            ?.VariantId
        "
      >
        <h3>
          Sub Process Flow
          <span
            v-if="
              testScenarioRunDetail.find((f) => f.id == testRunDetailId)
                ?.process?.VariantName
            "
            >-
            {{
              testScenarioRunDetail.find((f) => f.id == testRunDetailId)
                ?.process?.VariantName
            }}</span
          >
        </h3>
        <DrawIO
          :key="drawIoKey + 2"
          :variantId="
            testScenarioRunDetail.find((f) => f.id == testRunDetailId)?.process
              ?.VariantId
          "
          :highlightVariantId="
            testScenarioRunSteps.find((f) => f.id == testRunStepId)
              ?.ProcessStepId
          "
          height="45vh"
        />
      </div>
    </div>
    <div class="sticky" id="nested">
      <ejs-splitter orientation="Vertical" @resizeStop="onResize">
        <e-panes>
          <e-pane content="#breadcrumb" size="40px" :resizable="false"></e-pane>
          <e-pane content="#content"></e-pane>
        </e-panes>
      </ejs-splitter>
    </div>

    <div id="breadcrumb">Nav</div>
    <div id="content" class="content-pane">
      <TestRunContent
        :testScenarioRunSteps="
          testScenarioRunSteps.find((f) => f.id == testRunStepId)
        "
      />
      Content {{ testRunId }} / {{ testRunDetailId }} / {{ testRunStepId }}
    </div>
  </div>
</template>

<script>
import {
  SplitterComponent,
  PanesDirective,
  PaneDirective,
} from '@syncfusion/ej2-vue-layouts';

import { makeFindMixin } from 'feathers-vuex';

import TestRunTree from '@/components/TestScenarios/TestRunBrowser/TestRunTree.vue';
import TestRunContent from '@/components/TestScenarios/TestRunBrowser/TestRunContent.vue';
import DrawIO from '@/components/Drawing/drawio.vue';
export default {
  components: {
    'ejs-splitter': SplitterComponent,
    'e-pane': PaneDirective,
    'e-panes': PanesDirective,
    TestRunTree,
    TestRunContent,
    DrawIO,
  },
  data() {
    return {
      drawIoKey: 0,
    };
  },
  mixins: [
    makeFindMixin({
      service: 'test-scenario-run',
      watch: 'testRunId',
    }),
    makeFindMixin({
      service: 'test-scenario-run-detail',
      watch: 'testRunId',
    }),
    makeFindMixin({
      service: 'test-scenario-run-steps',
      watch: 'testRunDetailIds',
    }),
  ],
  computed: {
    testRunId() {
      return parseInt(this.$route.query.testRunId);
    },
    testRunDetailId() {
      return parseInt(this.$route.query.testRunDetailId);
    },
    testRunStepId() {
      return parseInt(this.$route.query.testRunStepId);
    },
    loading() {
      return (
        this.isFindTestScenarioRunPending ||
        this.isFindTestScenarioRunDetailPending ||
        this.isFindTestScenarioRunStepsPending
      );
    },
    testScenarioRunParams() {
      return {
        query: { id: this.testRunId },
      };
    },

    testScenarioRunDetailParams() {
      return {
        query: {
          TestScenarioRunId: this.testRunId,
          $sort: { Order: 1 },
        },
      };
    },
    testRunDetailIds() {
      return this.testScenarioRunDetail.map((m) => m.id);
    },
    testScenarioRunStepsParams() {
      return {
        query: {
          TestScenarioRunDetailId: { $in: this.testRunDetailIds },
          $sort: { Number: 1 },
        },
      };
    },
  },

  methods: {
    onResize() {
      this.drawIoKey += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
#default-splitter {
  height: 100vh; /* Full viewport height */
  overflow: hidden; /* Prevent outer overflow */
}

.content-pane {
  height: calc(100vh - 90px); /* Adjust for any header or footer height */
  overflow-y: auto; /* Enable vertical scrolling only on content */
  /* Ensure proper box model */
  box-sizing: border-box;
}

#menu,
#schedule {
  padding: 10px; /* Optional: Add padding */
}

.content {
  height: 1000px; /* Example long content height */
}

/* Optional: Hide scrollbar for better aesthetics */
#menu::-webkit-scrollbar,
#schedule::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

#menu::-webkit-scrollbar-thumb,
#schedule::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5); /* Color of the scrollbar */
  border-radius: 4px; /* Rounded corners */
}

/* For Firefox */
#menu,
#schedule {
  scrollbar-width: thin; /* Use thin scrollbar */
  scrollbar-color: rgba(0, 0, 0, 0.5) transparent; /* Thumb color and background */
}
</style>
