var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',[_c('v-text-field',{staticClass:"mb-5",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":""},on:{"click:clear":function($event){_vm.search = null}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',_vm._l((_vm.filteredSpecifications),function(spec,i){return _c('v-col',{key:spec.id,attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"mr-1",attrs:{"loading":_vm.isFindSpecificationPending,"height":"100%"}},[_c('template',{slot:"progress"},[(_vm.isFindSpecificationPending)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"secondary"}}):_vm._e()],1),(!_vm.isFindSpecificationPending)?_c('v-card-title',{staticClass:"subtitle-2",class:{ obsolete: spec.Obsolete }},[_vm._v(_vm._s(spec.Description1)+" ")]):_vm._e(),(spec.specification_type && spec.priority)?_c('v-card-subtitle',{staticClass:"pb-0"},[_vm._v(_vm._s(spec.specification_type.Description1)+" - "+_vm._s(spec.specification_type.Code)+_vm._s(spec.Number.toString().padStart(4, '0'))+" "),_c('span',{staticClass:"caption"},[_vm._v("("+_vm._s(spec.priority.Name)+")")])]):_vm._e(),(
            spec.Description2 ||
            spec.Description3 ||
            spec.Description4 ||
            _vm.listVariants(spec)
          )?_c('v-card-text',{staticClass:"ma-0"},[_c('v-list',{attrs:{"dense":""}},[(spec.Description2)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(spec.specification_type.Description2))]),_c('v-list-item-subtitle',[_c('span',{staticClass:"text-body-2 ma-0 pa-0 ck-content",domProps:{"innerHTML":_vm._s(spec.Description2)}})])],1)],1):_vm._e(),(spec.Description3)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(spec.specification_type.Description3))]),_c('v-list-item-subtitle',[_c('span',{staticClass:"text-body-2 ma-0 pa-0 ck-content",domProps:{"innerHTML":_vm._s(spec.Description3)}})])],1)],1):_vm._e(),(spec.Description4)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(spec.specification_type.Description4))]),_c('v-list-item-subtitle',[_c('span',{staticClass:"text-body-2 ma-0 pa-0 ck-content",domProps:{"innerHTML":_vm._s(spec.Description4)}})])],1)],1):_vm._e(),(_vm.listVariants(spec).length > 0)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Process")]),_c('v-list-item-subtitle',[_c('ul',_vm._l((_vm.listVariants(spec)),function(v){return _c('li',{key:v.id},[_vm._v(" "+_vm._s(`${v.process.Number}. ${v.process.Name} (${v.Name})`)+" "),(
                          (_vm.$feature('cloudinaryChartIntegration') &&
                            _vm.processUrl) ||
                          (_vm.$feature('lucidChartIntegration') && _vm.lucidUrl) ||
                          (_vm.$feature('drawIOChartIntegration') &&
                            _vm.drawingUrl(v))
                        )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-4 mb-2",on:{"click":function($event){_vm.openWindow(
                                _vm.$feature('drawIOChartIntegration') &&
                                  _vm.drawingUrl(v)
                                  ? _vm.drawingUrl(v)
                                  : _vm.$feature('lucidChartIntegration') &&
                                    _vm.lucidUrl
                                  ? _vm.lucidUrl
                                  : _vm.processUrl
                              )}}},'v-icon',attrs,false),on),[_vm._v(" mdi-image-search-outline ")])]}}],null,true)},[_c('span',[_vm._v("Process Flows")])]):_vm._e()],1)}),0)])],1)],1):_vm._e()],1)],1):_vm._e(),_c('v-card-subtitle',{staticClass:"mb-6 py-0"},[_vm._v("Response "),(_vm.vendorResponseFinal(spec) == 2)?_c('v-icon',{staticClass:"mr-1",attrs:{"color":"green","small":""}},[_vm._v("mdi-check-bold")]):_vm._e(),(_vm.vendorResponseFinal(spec) == 1)?_c('v-icon',{staticClass:"mr-1",attrs:{"color":"#FFC698","small":""}},[_vm._v("mdi-close-thick")]):_vm._e(),(_vm.vendorResponseFinal(spec) == 0)?_c('v-icon',{staticClass:"mr-1",attrs:{"color":"red","small":""}},[_vm._v("mdi-close-thick")]):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.toggle(i)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.show[i] ? 'mdi-chevron-up' : 'mdi-chevron-down'))])],1)],1),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show[i]),expression:"show[i]"}]},[_c('v-divider',{staticClass:"mx-4"}),_c('v-card-text',{staticClass:"mb-14"},[_c('SpecificationResponse',{attrs:{"specification":spec,"complexity":_vm.complexity,"implementation":_vm.implementation,"response":_vm.getResponse(spec),"vendor":_vm.vendor}})],1)],1)])],2)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }