<template>
  <v-container>
    <h1>Dashboard</h1>
    <v-tabs v-model="tab" active-class="activeTab">
      <v-tab v-if="$can('read', 'dashboard')">BI</v-tab>
      <v-tab v-if="$can('read', 'dashboard')">Progress</v-tab>
      <v-tab v-if="$can('read', 'dashboardExtendend')">Budget</v-tab>
      <v-tab v-if="$can('read', 'dashboardExtendend')">RFP Response</v-tab>
    </v-tabs>
    <v-tabs-items class="mb-5" v-model="tab">
      <v-tab-item>
        <BIOverview v-if="isBIEnabled" />
        <div v-else>BI is not enabled.</div></v-tab-item
      >
      <v-tab-item> <ProgressOverview /> </v-tab-item>
      <v-tab-item> Budget </v-tab-item>
      <v-tab-item> <RFPOverview /></v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import RFPOverview from '@/components/Dashboard/RFPStatus/RFPOverview.vue';
import ProgressOverview from '@/components/Dashboard/Progress/ProgressOverview.vue';
import BIOverview from '@/components/Dashboard/BIOverview.vue';
import { mapGetters } from 'vuex';

export default {
  components: { RFPOverview, ProgressOverview, BIOverview },
  data() {
    return {
      tab: 0,
    };
  },
  computed: {
    ...mapGetters(['isBIEnabled']),
  },
};
</script>

<style lang="scss" scoped></style>
