<template>
  <div>
    <v-dialog :value="true" persistent max-width="1100px" :retain-focus="false">
      <div v-if="item">
        <v-form ref="form" v-model="validInput">
          <v-card flat>
            <v-card-title>
              <span class="headline">New/Edit Reply</span>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12" md="12">
                  <RichEditor
                    v-model="item.Reply"
                    :disabled="
                      !(
                        $can('update', item, 'external-issue-reply') ||
                        $can('create', 'external-issue-reply')
                      )
                    "
                    placeholder="Reply"
                    @imageUploadProgress="imageUploadProgress = $event"
                  ></RichEditor>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                v-if="$can('delete', 'external-issue-reply')"
                outlined
                @click="showRemoveMessage = true"
                >Remove</v-btn
              >
              <div v-if="showRemoveMessage" class="ml-5">
                Are you sure?
                <v-btn class="ml-2" @click="$emit('remove')" color="error"
                  >Yes</v-btn
                >
              </div>
              <v-spacer></v-spacer>
              <v-btn outlined @click="$emit('cancel')">Cancel</v-btn>
              <v-btn
                :disabled="!validInput"
                :loading="saving || isUploadingImage"
                color="primary"
                @click="$emit('save')"
              >
                Save
                <template v-slot:loader>
                  <v-progress-circular
                    color="white"
                    :rotate="-90"
                    :value="imageUploadProgress"
                  ></v-progress-circular>
                </template>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import RichEditor from '@/components/general/RichEditor.vue';

export default {
  name: 'IssueEditDialog',
  components: {
    RichEditor,
  },
  data() {
    return {
      validInput: true,
      errorMessage: null,
      showRemoveMessage: false,
      imageUploadProgress: 0,
      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
      },
    };
  },
  computed: {
    isUploadingImage() {
      return this.imageUploadProgress > 0 && this.imageUploadProgress < 100;
    },
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    saving: { type: Boolean },
  },
};
</script>
