var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.testScenarioLine,"items-per-page":20,"sort-by":['Order'],"sort-desc":[false],"item-key":"id","disable-pagination":"","show-expand":"","dense":"","hide-default-footer":true,"loading":_vm.isFindTestScenarioLinePending,"loading-text":"Loading... Please wait","item-class":_vm.getRowClass},scopedSlots:_vm._u([{key:`item.flow`,fn:function({ item }){return [(_vm.$feature('drawIOChartIntegration'))?_c('DrawIO',{attrs:{"fullscreen":true,"variantId":item.VariantId,"iconOnly":true}}):_vm._e()]}},{key:`item.steps`,fn:function({ item }){return [(!item.Detailed)?_c('div',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-details")])],1):_c('div',[_vm._v(_vm._s(item.totalSteps))])]}},{key:`item.Order`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.Order)+" ")]}},{key:`item.Tests`,fn:function({ item }){return [_c('v-chip',{attrs:{"x-small":"","color":item.testCounterSuccess == item.testCounterAll &&
          item.testCounterAll != 0
            ? 'secondary'
            : item.testCounterSuccess < item.testCounterAll
            ? 'warning'
            : '',"text-color":_vm.applyDark(
            item.testCounterSuccess == item.testCounterAll &&
              item.testCounterAll != 0
              ? '#d1f5ed'
              : item.testCounterSuccess < item.testCounterAll
              ? 'colors.orange.base'
              : ''
          )
            ? 'white'
            : 'black'}},[_vm._v(" "+_vm._s(item.testCounterSuccess)+" /"+_vm._s(item.testCounterAll))])]}},{key:`item.issues`,fn:function({ item }){return [(_vm.issuesBlocking(item.issues)?.length > 0)?_c('v-chip',{attrs:{"x-small":"","color":"red","text-color":_vm.applyDark('red') ? 'white' : 'black'},on:{"click":function($event){_vm.showIssues([
            ..._vm.issuesBlocking(item.issues),
            ..._vm.issuesNonBlocking(item.issues),
          ])}}},[_vm._v(" "+_vm._s(_vm.issuesBlocking(item.issues)?.length)+" ")]):_vm._e(),(_vm.issuesNonBlocking(item.issues)?.length > 0)?_c('v-chip',{attrs:{"x-small":"","color":"pink lighten-4","text-color":'black'},on:{"click":function($event){_vm.showIssues([
            ..._vm.issuesNonBlocking(item.issues),
            ..._vm.issuesBlocking(item.issues),
          ])}}},[_vm._v(" "+_vm._s(_vm.issuesNonBlocking(item.issues)?.length))]):_vm._e()]}},{key:`item.Testable`,fn:function({ item }){return [(item?.TestableSteps - item?.NrOfSteps == 0)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v("mdi-close")])]}}],null,true)},[_vm._v(_vm._s(item.TestableSteps)+"/"+_vm._s(item.NrOfSteps))])]}},{key:`item.edit`,fn:function({ item }){return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.EditLine(item)}}},[_vm._v("mdi-pencil")])]}},{key:"expanded-item",fn:function({ item }){return [_c('td',{attrs:{"colspan":_vm.headers.length}},[_c('v-row',[_c('v-col',{staticClass:"mt-2"},[_c('TestScenarioLineSteps',{attrs:{"keyReload":_vm.key,"testScenarioLine":item}})],1)],1)],1)]}}],null,true)}),(_vm.testScenarioLine && _vm.showDialog)?_c('div',[(_vm.showDialog)?_c('TestScenarioEdit',{attrs:{"currentItem":_vm.currentItem,"variant":{},"testScenarioHeader":_vm.testScenarioHeader,"maxOrder":0},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}}):_vm._e()],1):_vm._e(),(_vm.issueDialog)?_c('v-dialog',{attrs:{"hide-overlay":"","width":"90vw","height":"auto"},model:{value:(_vm.issueDialog),callback:function ($$v) {_vm.issueDialog=$$v},expression:"issueDialog"}},[_c('v-card',{staticClass:"pa-5",attrs:{"height":"90vh"}},[(_vm.issueDialog)?_c('IssueList',{attrs:{"ids":_vm.issuesIds},model:{value:(_vm.issueDialog),callback:function ($$v) {_vm.issueDialog=$$v},expression:"issueDialog"}}):_vm._e()],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }