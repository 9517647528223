<template>
  <FeathersVuexFormWrapper :item="item" watch v-if="showDialog && item">
    <template v-slot="{ clone, save, reset, remove }">
      <SpecificationEditDialog
        :item="clone"
        :saving="saving"
        @save="
          saving = true;
          save()
            .then(async (saved) => {
              handleSaveResponse(
                saved.Description1,
                'specification',
                currentItem ? 'updated' : 'created'
              );
              const { SpecificationVariant } = $FeathersVuex.api;
              let exists = await SpecificationVariant.find({
                query: {
                  VariantId: saved.VariantId,
                  SpecificationId: saved.id,
                },
              });
              if (exists.data.length == 0 && saved.VariantId != null) {
                let newRecord = new SpecificationVariant();
                newRecord.VariantId = saved.VariantId;
                newRecord.SpecificationId = saved.id;
                newRecord.save().then(async (saved2) => {
                  handleSaveResponse(
                    saved2.id,
                    'specification variant',
                    'saved'
                  );
                  await refresh();
                  showDialog = false;
                  saving = fasle;
                });
              } else {
                await refresh();
                showDialog = false;
                saving = false;
              }
            })
            .catch(async (error) => {
              handleErrorResponse(error);
              await refresh();
              saving = false;
            });
        "
        @reset="reset"
        @remove="
          remove()
            .then(async (saved) => {
              handleSaveResponse(
                saved.Description1,
                'specification',
                'removed'
              );

              await refresh();
            })
            .catch(async (error) => {
              handleErrorResponse(error);
              await refresh();
            });

          showDialog = false;
        "
        @cancel="showDialog = false"
      ></SpecificationEditDialog>
    </template>
  </FeathersVuexFormWrapper>
</template>

<script>
import { FeathersVuexFormWrapper } from 'feathers-vuex';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';

import SpecificationEditDialog from '@/components/Specification/SpecificationEditDialog';

export default {
  name: 'SpecificationEdit',
  components: {
    FeathersVuexFormWrapper,
    SpecificationEditDialog,
  },
  data() {
    return { item: null, saving: false };
  },
  props: {
    value: { type: Boolean },
    currentItem: {
      type: Object,
      required: false,
    },
    variant: {},
    type: {},
  },
  watch: {
    currentItem() {
      const { Specification } = this.$FeathersVuex.api;
      if (this.currentItem) {
        this.item = this.currentItem;
      } else {
        let newSpecification = new Specification();
        newSpecification.SpecificationTypeId = this.type.id;
        newSpecification.VariantId = this.variant ? this.variant.id : null;

        this.item = newSpecification;
      }
    },
  },
  computed: {
    specVariant() {
      if (this.variant) {
        const { SpecificationVariant } = this.$FeathersVuex.api;
        let newRecord = new SpecificationVariant();
        newRecord.VariantId = this.variant.id;
        return newRecord;
      } else {
        return null;
      }
    },

    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    handleSaveResponse,
    handleErrorResponse,
    async refresh() {
      const { Specification, SpecificationVariant } = this.$FeathersVuex.api;
      await Specification.find({
        query: {},
      });
      await SpecificationVariant.find({
        query: {},
      });
    },
  },
  mounted() {
    const { Specification } = this.$FeathersVuex.api;
    if (this.currentItem) {
      this.item = this.currentItem;
    } else {
      let newSpecification = new Specification();
      newSpecification.SpecificationTypeId = this.type.id;
      newSpecification.VariantId = this.variant ? this.variant.id : null;

      this.item = newSpecification;
    }
  },
};
</script>
