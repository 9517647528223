<template>
  <div>
    <v-expansion-panels accordion multiple>
      <v-expansion-panel v-for="department in department" :key="department.id">
        <v-expansion-panel-header>
          {{ department.Name }}
          ({{
            testScenarioHeaderTestCase.filter(
              (f) => f.test_case?.DepartmentId == department.id
            )?.length
          }})
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <TestCaseList
            @reload="reload++"
            :departmentId="department.id"
            :testScenarioHeaderId="testScenarioHeader.id"
            :testCases="
              testScenarioHeaderTestCase.filter(
                (f) => f.test_case?.DepartmentId == department.id
              )
            "
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import TestCaseList from './TestCaseList.vue';

export default {
  components: {
    TestCaseList,
  },
  props: {
    testScenarioHeader: {
      type: Object,
      required: true,
    },
  },
  mixins: [
    makeFindMixin({
      service: 'test-scenario-header-test-case',
      watch: ['testScenarioHeader.id', 'reload'],
    }),
    makeFindMixin({
      service: 'test-scenario-line',
      watch: 'testScenarioHeader.id',
    }),
    makeFindMixin({
      service: 'test-case',
      watch: 'DepartmentIds',
    }),
    makeFindMixin({
      service: 'department',
      watch: 'DepartmentIds',
    }),
  ],
  data() {
    return {
      reload: 0,
    };
  },
  computed: {
    testScenarioHeaderTestCaseParams() {
      return {
        query: {
          TestScenarioHeaderId: this.testScenarioHeader.id,
        },
      };
    },
    testScenarioLineParams() {
      return {
        query: {
          TestScenarioHeaderId: this.testScenarioHeader.id,
        },
      };
    },
    DepartmentIds() {
      return this.testScenarioLine.map((m) => m.variant.process.DepartmentId);
    },
    testCaseParams() {
      return {
        query: {
          DepartmentId: { $ind: this.DepartmentIds },
        },
      };
    },
    departmentParams() {
      return {
        query: {
          id: { $in: this.DepartmentIds },
          $sort: { Name: 1 },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
