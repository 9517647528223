<template>
  <div>
    <v-dialog
      :value="true"
      persistent
      :max-width="width"
      @keydown.esc.stop="$emit('cancel')"
      :retain-focus="false"
    >
      <v-card>
        <v-card-title class="text-h5"> {{ title }}</v-card-title>
        <v-card-text>{{ body }}</v-card-text>
        <v-card-text><slot></slot></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!hideCancel"
            outlined
            @click="$emit('cancel')"
            :disabled="disabledCancel"
            >Cancel</v-btn
          >
          <v-btn
            v-if="!hideConfirm"
            :loading="busy"
            color="primary"
            @click="$emit('confirm')"
            :disabled="disabledConfirm"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    body: {
      type: String,
      required: false,
    },
    busy: {
      type: Boolean,
      required: false,
    },
    width: {
      type: String,
      required: false,
      default: '400px',
    },
    hideCancel: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideConfirm: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabledCancel: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabledConfirm: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped></style>
