<template>
  <div v-if="testScenarioLineStepField?.length > 0">
    Fields:

    <ul>
      <li v-for="field of testScenarioLineStepField" :key="field.id">
        <b>{{ field.fieldvaluelist.field.Name }}</b
        >: <span v-html="field.fieldvaluelist.Value" class="ck-content"></span>
      </li>
    </ul>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';

export default {
  mixins: [
    makeFindMixin({
      service: 'test-scenario-line-step-field',
      watch: 'testScenarioLineStep.id',
    }),
  ],
  props: {
    testScenarioLineStep: {
      type: Object,
      required: true,
    },
  },

  computed: {
    testScenarioLineStepFieldParams() {
      return {
        query: {
          TestScenarioLineStepId: this.testScenarioLineStep?.id,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
