var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"value":true,"persistent":"","max-width":"100vw","retain-focus":false}},[(_vm.item)?_c('div',[_c('v-form',{ref:"form",model:{value:(_vm.validInput),callback:function ($$v) {_vm.validInput=$$v},expression:"validInput"}},[_c('v-card',{staticClass:"px-5",attrs:{"flat":""}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("New/Edit Test Scenario")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"align":"right","cols":"12","md":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({on:{"click":_vm.copyVariantName}},'v-icon',attrs,false),on),[_vm._v("mdi-arrow-right-bottom-bold")])]}}],null,false,1613532688)},[_c('span',[_vm._v("Copy Variant Name")])])],1),_c('v-col',{staticClass:"justify-right",attrs:{"cols":"12","md":"10"}},[(_vm.rerender)?_c('v-text-field',{attrs:{"label":"Name","rules":[_vm.rules.required],"disabled":!_vm.$can('update', _vm.item, 'Name'),"outlined":"","hide-details":"true","dense":""},on:{"change":_vm.fieldChanged},model:{value:(_vm.item.Name),callback:function ($$v) {_vm.$set(_vm.item, "Name", $$v)},expression:"item.Name"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[(_vm.$feature('drawIOChartIntegration'))?_c('DrawIO',{staticClass:"mt-1",attrs:{"fullscreen":true,"variantId":_vm.item.VariantId,"iconOnly":true}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('ApplicationList',{attrs:{"variant":_vm.item.variant,"allowSelection":"","selectedErpApplicationIds":_vm.item.ErpApplicationIds},on:{"updateSelectedErpApplicationIds":_vm.updateErpApplicationIds}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('RichEditor',{attrs:{"disabled":!(
                      _vm.$can('update', _vm.item, 'Description') ||
                      _vm.$can('create', 'Description')
                    ),"placeholder":"Description"},on:{"change":_vm.fieldChanged,"imageUploadProgress":function($event){_vm.imageUploadProgress = $event}},model:{value:(_vm.item.Description),callback:function ($$v) {_vm.$set(_vm.item, "Description", $$v)},expression:"item.Description"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('TestScenarioOutput',{staticClass:"mb-5",attrs:{"testScenarioLine":_vm.item}}),_c('TestScenarioInput',{attrs:{"testScenarioLine":_vm.item}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-switch',{staticClass:"my-2",attrs:{"disabled":!_vm.$can('update', _vm.item, 'Detailed'),"label":"Detailed","dense":"","outlined":"","hide-details":"true"},on:{"change":_vm.fieldChanged},model:{value:(_vm.item.Detailed),callback:function ($$v) {_vm.$set(_vm.item, "Detailed", $$v)},expression:"item.Detailed"}})],1),_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"12","md":"10","align":"right"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showProcessLinks = !_vm.showProcessLinks}}},[_c('v-icon',[_vm._v(_vm._s(_vm.showProcessLinks ? 'mdi-chevron-up' : 'mdi-chevron-down'))])],1)],1)],1),_c('v-expand-transition',[(_vm.showProcessLinks)?_c('v-row',{staticClass:"mb-5"},[(
                    _vm.item.variant.Output?.trim().length > 0 ||
                    _vm.item.variant.Input?.trim().length > 0
                  )?_c('v-col',{attrs:{"cols":"12","md":"12"}},[(_vm.item.variant.Input?.trim().length > 0)?_c('div',[_vm._v(" Input: "),_c('span',{staticClass:"ck-content",domProps:{"innerHTML":_vm._s(_vm.item.variant.Input)}})]):_vm._e(),(_vm.item.variant.Output?.trim().length > 0)?_c('div',[_vm._v(" Output: "),_c('span',{staticClass:"ck-content",domProps:{"innerHTML":_vm._s(_vm.item.variant.Output)}})]):_vm._e()]):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('router-link',{attrs:{"to":{
                      name: 'processDetail',
                      params: {
                        id: _vm.item.variant.process.id,
                        number: _vm.item.variant.process.Number,
                      },
                    }}},[_vm._v(_vm._s(_vm.$appConfig.customer.prefix)+_vm._s(_vm.item.variant.process.Number.toString().padStart(4, '0'))+" - "+_vm._s(_vm.item.variant.process.Name))]),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-right")]),_c('router-link',{attrs:{"to":{
                      name: 'processStep',
                      params: { id: _vm.item.variant.id },
                    }}},[_vm._v(_vm._s(_vm.item.variant.Number)+" - "+_vm._s(_vm.item.variant.Name))])],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"disabled":!_vm.$can('update', _vm.item, 'ProcessId'),"items":_vm.processList,"item-text":"fullname","item-value":"id","label":"Process","outlined":"","clearable":"","dense":"","hide-details":"true"},on:{"change":function($event){_vm.item.VariantId = null}},model:{value:(_vm.item.variant.ProcessId),callback:function ($$v) {_vm.$set(_vm.item.variant, "ProcessId", $$v)},expression:"item.variant.ProcessId"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"disabled":!_vm.$can('update', _vm.item, 'VariantId'),"items":_vm.variantList,"item-text":"fullname","item-value":"id","rules":[_vm.rules.required],"label":"Variant","outlined":"","clearable":"","dense":"","hide-details":"true"},on:{"change":function($event){_vm.item.variant.id = _vm.item.VariantId;
                      _vm.toggle();}},model:{value:(_vm.item.VariantId),callback:function ($$v) {_vm.$set(_vm.item, "VariantId", $$v)},expression:"item.VariantId"}})],1)],1):_vm._e()],1),_c('hr'),(_vm.item.VariantId && _vm.showSteps && _vm.item.id)?_c('TestScenarioSteps',{staticClass:"my-5",attrs:{"variant":_vm.item.variant,"testScenarioLine":_vm.item,"stepsToAdd":_vm.stepsToAdd,"addModus":_vm.addModus,"testLines":_vm.testScenarioLine},on:{"updateSteps":_vm.updateSteps,"saving":(saving) => (this.savingSteps = saving)}}):_vm._e()],1),_c('v-card-actions',[(_vm.$can('delete', _vm.item))?_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){_vm.showRemoveMessage = true}}},[_vm._v("Remove")]):_vm._e(),(_vm.showRemoveMessage)?_c('div',{staticClass:"ml-5"},[_vm._v(" Are you sure? "),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"error"},on:{"click":function($event){return _vm.$emit('remove')}}},[_vm._v("Yes")])],1):_vm._e(),_c('v-spacer'),(!_vm.item.id)?_c('div',[_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"disabled":!_vm.validInput,"color":"primary","loading":_vm.saving || _vm.isUploadingImage},on:{"click":function($event){return _vm.$emit('save')}},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('v-progress-circular',{attrs:{"color":"white","rotate":-90,"value":_vm.imageUploadProgress}})]},proxy:true}],null,false,3999508698)},[_vm._v(" Save ")])],1):_c('div',[_c('v-btn',{attrs:{"disabled":!_vm.validInput,"color":"primary","loading":_vm.saving || _vm.savingSteps || _vm.isUploadingImage},on:{"click":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('v-progress-circular',{attrs:{"color":"white","rotate":-90,"value":_vm.imageUploadProgress}})]},proxy:true}],null,false,3999508698)},[_vm._v(" Close ")])],1)],1)],1)],1)],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }