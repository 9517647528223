<template>
  <span v-if="users?.length > 0">
    <v-tooltip v-if="users[0].gravatarUrl" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-avatar
          v-bind="attrs"
          v-on="on"
          small
          :class="
            applyDark(users[0].Color ? users[0].Color : '#000000')
              ? 'white--text caption mr-3'
              : 'black--text caption mr-3'
          "
          text--color="white"
          :color="users[0].Color ? users[0].Color : '#000000'"
          size="20"
        >
          <img
            :src="users[0].gravatarUrl"
            :alt="users[0].fullname"
          /> </v-avatar></template
      >{{ users[0].fullname }}</v-tooltip
    >
    <v-tooltip v-else bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-avatar
          v-bind="attrs"
          v-on="on"
          small
          :class="
            applyDark(users[0].Color ? users[0].Color : '#000000')
              ? 'white--text caption mr-3'
              : 'black--text caption mr-3'
          "
          text--color="white"
          :color="users[0].Color ? users[0].Color : '#000000'"
          size="20"
        >
          <span v-bind="attrs" v-on="on">{{
            users[0].fullname
              .match(/(\b\S)?/g)
              .join('')
              .match(/(^\S|\S$)?/g)
              .join('')
              .toUpperCase()
          }}</span>
        </v-avatar></template
      >{{ users[0].fullname }}</v-tooltip
    >
  </span>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import { applyDark } from '@/utils/Utilities';
export default {
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  mixins: [makeFindMixin({ service: 'users' })],
  computed: {
    usersParams() {
      return { query: { id: this.userId } };
    },
  },
  methods: { applyDark },
};
</script>

<style lang="scss" scoped></style>
